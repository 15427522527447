import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {import("../../types").Reducer.Wallet}
 */
const initialState = {
  balance: {
    available: 0,
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    $SET: (state, action) => {
      return { state, ...action.payload };
    },
    $CLEAR: (state, action) => {
      return initialState;
    },
  },
});

export const actions = walletSlice.actions;
export default walletSlice.reducer;
