// Activity.List.tsx
import { connectToStore } from "../../lib";
import { Button } from "@mantine/core";
import { List } from "../../components/Activity";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { routeMap } from "../../route-map";

function ActivityListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="flex justify-between items-baseline">
        <Button size="md"
          onClick={() =>
            navigate(routeMap?.account?.event_activity_add, {
              state: { from: location?.pathname },
            })
          }
        >
          Create new
        </Button>
      </div>
      <List />
    </>
  );
}
export default connectToStore(ActivityListPage);
