/*
  **Setting Context**

  THis is the context object for the app settings
*/

import { createContext, useState, useEffect, useRef } from "react";
import * as themeConfig from "../config/theme";
import { MantineTheme } from "@mantine/core";
import { useToggle } from "../hook";

const initialState = {
  theme: "light",
  showSideNavigation: true,
};

export const SettingContext = createContext({
  get: () => null,

  updateSettingValues: (opts) => null,

  resetSetting: () => null,
  ...initialState,
  onToggleTheme: () => {
    return;
  },
});

const useColorScheme = (defaultValue = "light") => {
  /**
   * @type {[colorScheme: "light" | "dark", setColorScheme: () => void]}
   * */
  const [colorScheme, setColorScheme] = useState(defaultValue);

  const setColorSchemePropsInDOM = (value) => {
    document.body.setAttribute("data-theme", value);
    setColorScheme(value);
  };

  function onToggleColorScheme() {
    const fromLocalStore = localStorage.getItem("theme");
    const value = fromLocalStore === "light" ? "dark" : "light";
    localStorage.setItem("theme", value);
    setColorSchemePropsInDOM(value);
  }

  useEffect(() => {
    const fromLocalStore = localStorage.getItem("theme");

    if (!fromLocalStore) {
      // document.documentElement.style.display = 'none'

      if (window) {
        if (window.matchMedia("(prefers-color-scheme)").media !== "not all") {
          const darkModeMediaQuery = window.matchMedia(
            "(prefers-color-scheme: dark)"
          );

          let darkModeActive = darkModeMediaQuery.matches;
          let value = darkModeActive ? "dark" : "light";
          if (!colorScheme) {
            setColorScheme(value);
            localStorage.setItem("theme", value);
          }

          darkModeMediaQuery.addEventListener("change", (e) => {
            darkModeActive = e.matches;
            value = darkModeActive ? "dark" : "light";
            setColorScheme(value);
            localStorage.setItem("theme", value);
          });
        }
      }
    } else {
      setColorScheme(fromLocalStore);
    }
  }, [colorScheme]);

  return { colorScheme, onToggleColorScheme };
};

export const SettingProvider = ({ children }) => {
  const [setting, setSetting] = useState(initialState);
  const isMounted = useRef(true);
  const { onToggleColorScheme, colorScheme } = useColorScheme("light");
  const {
    isOpen: showSideNavigation,
    onToggle: toggleShowSideNavigation,
    onClose: onCloseSideNavigation,
  } = useToggle(true);

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateSettingValues = (opts) => {
    const res = {};
    Object.entries(opts).forEach(([k, v]) => {
      res[k] = initialState[k] ?? v;
    });

    setSetting({ ...setting, ...res });
  };

  /**
   * @description Modifies shared setting state
   * @param {string} key
   * @param {any} value
   */
  function updateSettingValue(key, value) {
    if (key in initialState) {
      setSetting({ ...setting, [key]: value });
    }
  }

  const resetSetting = () => {
    setSetting(initialState);
  };

  return (
    <SettingContext.Provider
      value={{
        resetSetting,
        updateSettingValue,
        updateSettingValues,
        onToggleColorScheme,
        colorScheme: themeConfig[colorScheme],
        toggleShowSideNavigation,
        showSideNavigation,
        onCloseSideNavigation,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};
