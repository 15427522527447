// useToggle.js
import { useState } from "react";

function useToggle(value: boolean) {
  const [isOpen, setIsOpen] = useState<boolean>(value || false);

  function onOpen() {
    setIsOpen(true);
  }
  function onBeforeOpen(cb = () => null) {
    cb();
    onOpen();
  }
  function onAfterOpen(cb = () => null) {
    onOpen();
    cb();
  }

  function onClose() {
    setIsOpen(false);
  }
  function onBeforeClose(cb = () => null) {
    cb();
    onClose();
  }
  function onAfterClose(cb = () => null) {
    onClose();
    cb();
  }

  function onToggle() {
    setIsOpen(!isOpen);
  }

  return {
    isOpen,
    onToggle,
    onBeforeOpen,
    onOpen,
    onAfterOpen,
    onBeforeClose,
    onClose,
    onAfterClose,
  };
}

export default useToggle;
