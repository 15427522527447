// AddForm.jsx
import { connectToStore } from "../../lib";
import Card from "../Card";
import { Button, TextInput, Select } from "@mantine/core";
import { AddIcon, ArrowDownIcon, MenuIcon, QuestionIcon } from "../Svgs";

function AddForm() {
  return (
    <>
      <Card title="Add Form" fullWidth={true}>
        <TextInput label="Name" />
        <div className="row">
          <div className="inputBox1">
            <TextInput label="Title" placeholder="First name" />
          </div>
          <div className="mobile-row">
            <div className="inputBox1">
              <Select data={[]} label="Type" showInfoIcon={false} />
            </div>
            <div className="round">
              <ArrowDownIcon />
            </div>
            <div className="round">
              <MenuIcon />
            </div>
            <div className="round">
              <AddIcon fill="#8C8C8C" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="inputBox2">
            <TextInput label="Title" placeholder="Last name" />
          </div>
          <div className="mobile-row">
            <div className="inputBox2">
              <Select data={[]} label="Type" showInfoIcon={false} />
            </div>
            <div className="inputBox2">
              <Select data={[]} label="Key" showInfoIcon={false} />
            </div>
            <div className="round">
              <MenuIcon />
            </div>
          </div>
        </div>
        <div className="option-row">
          <span>Voting cost</span>
          <div className="rowIcons">
            <AddIcon fill="#F78F08" />
            <QuestionIcon />
          </div>
        </div>
        <div className="outlined-box">
          <Select
            data={[]}
            label="Currency"
            showInfoIcon={false}
            placeholder="NGN"
          />
          <Select
            data={[]}
            label="Cost"
            showInfoIcon={false}
            placeholder="500"
          />
          <button>Add</button>
        </div>
        <div className="option-row">
          <span>Registration cost</span>
          <div className="rowIcons">
            <AddIcon fill="#F78F08" />
            <QuestionIcon />
          </div>
        </div>
        <div className="outlined-box">
          <Select
            label="Currency"
            showInfoIcon={false}
            placeholder="NGN"
            data={[]}
          />
          <Select
            label="Cost"
            data={[]}
            showInfoIcon={false}
            placeholder="500"
          />
          <button>Add</button>
        </div>
        <div className="submit">
          <Button> Create Form</Button>
        </div>
      </Card>
    </>
  );
}

export default connectToStore(AddForm);
