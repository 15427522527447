import { MantineTheme } from "@mantine/core";
import defaultConfig from "./default";

// dark.theme.ts
const config: Partial<MantineTheme> = {
  ...defaultConfig,
  colorScheme: "dark",
  colors: {
    ...defaultConfig?.colors,
    // override dark colors to change them for all components
    dark: [
      "#d5d7e0",
      "#acaebf",
      "#8c8fa3",
      "#666980",
      "#4d4f66",
      "#34354a",
      "#2b2c3d",
      "#1d1e30",
      "#0c0d21",
      "#01010a",
    ],
  },
};

export default config;
