// AddJudge.jsx
import { useState } from "react";
import Card from "../Card";
import { Button, TextInput, Select, Textarea } from "@mantine/core";
import { UploadManager } from "../FormElement";
import { connectToStore } from "../../lib";

function AddJudge() {
  return (
    <Card title="Add Judge">
      <TextInput label="Full name" />
      <TextInput label="Email address" type="email" />
      <Textarea label="Bio" />
      <Select data={[]} label="Gender" />
      <UploadManager label="Avatar" />
      <Button>Finish</Button>
    </Card>
  );
}

export default connectToStore(AddJudge);
