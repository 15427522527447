// index.js
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { actions as UserAction } from "../store/reducer/user";
import { actions as WalletAction } from "../store/reducer/wallet";
import { actions as SessionAction } from "../store/reducer/session";
import { actions as SettingAction } from "../store/reducer/setting";
import { actions as DraftAction } from "../store/reducer/draft";

/**
 *
 * @param {Error|AxiosError} error Error Object
 * @param {boolean} [debug] Enable debugging
 */

// TODO: Better error message parsing

export const parseErrorMessage = (error, debug = false, type) => {
  toast.dismiss("parsed-error-message");
  let errResponse = error?.message;

  toast.error(
    `${errResponse}\n${type ? `[${type}]` : ""}` ||
      "Something went wrong! Try again",
    {
      id: "parsed-error-message",
      style: {
        minWidth: "250px",
      },
    }
  );
  // if (typeof errResponse === "string")
  //   toast.error(errResponse, { id: "parsed-error-message", style: {
  //         minWidth: "250px",
  //       }, });
  // else {
  //   toast.error(errResponse?.detail || "Something went wrong! Try again", {
  //     id: "parsed-error-message",
  //     style: {
  //         minWidth: "250px",
  //       },
  //     });
  // }

  if (debug) {
    console.error({
      message: error?.message,
      details: error?.response?.data?.detail,
      status: error?.response?.status,
    });
    // debugger;
  }
};

export function mapStateToProps(state) {
  return {
    UserState: state.user,
    WalletState: state.wallet,
    SettingState: state.setting,
    SessionState: state.session,
    DraftState: state.draft,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    UserDispatch: bindActionCreators(UserAction, dispatch),
    WalletDispatch: bindActionCreators(WalletAction, dispatch),
    SettingDispatch: bindActionCreators(SettingAction, dispatch),
    SessionDispatch: bindActionCreators(SessionAction, dispatch),
    DraftDispatch: bindActionCreators(DraftAction, dispatch),
  };
}

export const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export const copyToClipboard = (text) => {
  // Copy the text inside the text field
  navigator.clipboard.writeText(text);
};

export const firstLetters = (words, sep = " ") => {
  return words
    ? words
        .split(sep)
        .map((word) => word[0])
        .join("")
    : "";
};

export function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`;
  }
}

export const StyledContainer = styled.section`
  padding: 20px;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 15px;
  overflow-y: auto;
  @media screen and (max-width: 669px) {
    padding: 15px;
    row-gap: 15px;
  }
`;

export const patterns = {
  email: /^([^\s@]+@[^\s@]+\.[^\s@]+)$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  slug: /[A-Za-z0-9](_)+/
}