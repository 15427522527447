import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@fontsource/outfit";
import "@fontsource/mogra";
import "./index.scss";
import { Toaster } from "react-hot-toast";
import RoutingComponent from "./route-map";
import useSetting from "./hook/useSetting";
import { ThemeProvider } from "styled-components";
import { Suspense, lazy } from "react";
import { MantineProvider, Loader } from "@mantine/core";
import { ErrorFallback } from "./components/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import {nanoid} from "nanoid";

const App = () => {
  const { colorScheme } = useSetting();
  const [key, setKey] = useState(nanoid(10))
  try {
    return (
      <MantineProvider withGlobalStyles theme={colorScheme} withNormalizeCSS>
        <ThemeProvider theme={colorScheme}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              setKey(nanoid(10));
            }}
          >
            <Toaster position="top-center" reverseOrder={false} gutter={8} />
            <BrowserRouter key={key}>
              <Suspense fallback={<div className="w-full h-full items-center justify-center flex"><Loader/></div>}>
                <RoutingComponent />
              </Suspense>
            </BrowserRouter>
          </ErrorBoundary>          
        </ThemeProvider>
      </MantineProvider>
    );
  } catch (error) {
    console.error(error);
  }
};

export default App;
