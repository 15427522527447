// Selector.js
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connectToStore } from "../lib";
// import Modal from "@mui/material/Modal";
import { useToggle } from "../hook";
import { Modal, Button, Transition } from "@mantine/core";

const Header = () => {
  return (
    <header name="modal-modal-title" className="flex-wrap border-b border-1">
      <div className="w-full">
        <p>You can render any react element here. Use the {`<renderHeader>`}</p>
      </div>
    </header>
  );
};

const Body = ({ setEvent, event }) => {
  const [len, setLen] = useState(10);
  return (
    <div className="body">
      <ul className="list-container">
        {Array(len)
          .fill()
          ?.map((item, index) => ({
            id: index + 1,
            title: `Item ${index + 1}`,
          }))
          ?.map((item, index) => (
            <div
              onClick={() => setEvent(item)}
              key={item?.id}
              className={`min-h-[70px] list-item ${
                event?.id == item?.id ? "selected" : "not-selected"
              }`}
            >
              <p>{item?.title}</p>
            </div>
          ))}
      </ul>
      <div className="">
        <Button onClick={() => setLen(len + 10)} className="mt-2 w-full">
          Load More
        </Button>
      </div>
    </div>
  );
};

const Footer = ({ modal, event, setSelected, onFinish, size }) => {
  return (
    <footer>
      <Button color="red" variant="filled" onClick={modal?.onClose}>
        Exit
      </Button>

      <Button
        onClick={() => {
          setSelected(event);
          onFinish && onFinish(event);
          modal?.onClose();
        }}
        size={size}
      >
        Continue
      </Button>
    </footer>
  );
};

const EntryButton = ({ modal, selected, placeholder, ButtonStyle, size }) => {
  return (
    <Button
      color={"gray"}
      variant="outline"
      style={{ width: "100%", ...ButtonStyle }}
      type="button"
      size={size}
      onClick={modal?.onToggle}
    >
      {selected ? JSON.stringify(selected) : placeholder}
    </Button>
  );
};

function useRender(extraProps) {
  const [event, setEvent] = useState();
  const [selected, setSelected] = useState();
  const modal = useToggle();

  return {
    event,
    setEvent,
    selected,
    setSelected,
    modal,
    ...extraProps,
  };
}

function Selector({
  renderHeader: RenderHeader = Header,
  renderBody: RenderBody = Body,
  renderFooter: RenderFooter = Footer,
  renderButton: RenderButton = EntryButton,
  placeholder = "Set the <placeholder> prop to set the text",
  ButtonStyle,
  onFinish,
  size="sm"
}) {
  const renderProps = useRender({onFinish, size});

  return (
    <div className="my-[8px]">
      <Renderer {...renderProps} {...{ placeholder, ButtonStyle }}>
        {(props) => <RenderButton {...props} />}
      </Renderer>
      <Modal
          centered
          opened={renderProps?.modal.isOpen}
          onClose={renderProps?.modal.onClose}
          title={
            <Renderer {...renderProps}>
              {(props) => <RenderHeader {...props} />}
            </Renderer>
          }
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Transition mounted={renderProps?.modal.isOpen} 
          transition="scale-y" duration={400} timingFunction="ease">
            {(style) => <StyledContainer 
              style={style}
              className="">
                <Renderer {...renderProps}>
                  {(props) => <RenderBody {...props} />}
                </Renderer>
                <Renderer {...renderProps}>
                  {(props) => <RenderFooter {...props} />}
                </Renderer>
              </StyledContainer>
               }
          </Transition>
        </Modal>

    </div>
  );
}

function Renderer({ children, ...rest }) {
  return children(rest);
}

export default connectToStore(Selector);

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 63vh;
    overflow-y: auto;
    // background-color: #249aca;
    .list-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      row-gap: 8px;
      padding: 4px;

      .list-item {
        background: ${({ theme }) =>
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.dark[1]};
        border-radius: 8;
        padding: 8px;
        border-radius: 8px;
        &.selected {
          outline: 2px dashed ${({ theme }) => theme.colors.brand[1]};
          outline-offset: 2px;
        }
        &.not-selected {
        }
      }
    }
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    // position: sticky;
    // bottom: 0;
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    padding-top: 16px;
    // position: sticky;
    // bottom: 0;
  }
`;
