// form.js

import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "forms");
    this._name = "Form";
    return this;
  }

  /**
   * @function create
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.title
   * @param {string} args.data.slug
   * @param {string} args.data.image_field_label
   * @param {object} args.data.schema
   * @param {string} args.data.reg_type
   * @param {Array<{amount: number; currency: string;reason: string;in_stock: number, max_limit: number;infinite_stock: boolean}>} args.data.prices
   *  */
  async create({ data }) {
    const res = await this.axios(
      {
        url: "/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function update
   * @param {object} args
   * @param {string} args.id
   * @param {object} args.data
   * @param {Array<string>} args.data.gallary
   * @param {string} args.data.about
   * @param {string} args.data.name
   * */
  async update({ id, data }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "patch",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function retrieve
   * @param {object} args
   * @param {string} args.id
   * */
  async retrieve({ id }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @function list
   * @param {object} args
   * @param {string[]} args.ids
   * */
  async list({ ids = [] }) {
    const res = await this.axios(
      {
        url: `/?${ids?.map((id) => `ids[]=${id}`)?.join("&")}`,
        method: "get",
      },
      true
    );
    return res.data;
  }
}
