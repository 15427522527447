// AddActivity.jsx
import {Container, Stack, Grid, Group, Paper} from "@mantine/core"
import {connectToStore} from "../../lib"
import {Add} from "../Activity";
import ListCard from "../ListCard";

function AddActivity ({DraftDispatch, DraftState}) {
	return <div >
	 	<Grid grow gutter={"lg"} py="md" pl="md" sx={{maxWidth: "100%"}}>
			<Grid.Col md={4} className="grow-0">
				<Add implicitEvent/>
			</Grid.Col>

			<Grid.Col span={8} >
				<ListCard list={DraftState?.activity} >
					{({list}) => <>
						JSON.stringify(list)
					</>}
				</ListCard>
			</Grid.Col>
		</Grid>
	</div>
}


export default connectToStore(AddActivity)