// ListJudge.jsx
import { useState } from "react";
import ListCard from "../ListCard";
import { connectToStore } from "../../lib";

function ListJudge() {
  return <ListCard>{({ list }) => <></>}</ListCard>;
}

export default connectToStore(ListJudge);
