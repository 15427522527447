import styled, { css } from "styled-components";
import ideas from "../../images/ideas.png";
import { useLocation } from "react-router-dom";
import carbonIcon from "../../images/icons/contest.png";
const Ideas = () => {
  const { pathname } = useLocation();
  return (
    <IdeasStyled location={pathname}>
      <img src={ideas} className="idea-img" alt="" srcSet="" />
      <div className="ideaBx">
        <h2 className="title">
          Fresh ideas for your <br />
          contest
        </h2>
        <p className="content">
          Photo Voting Contest You can make your photo contests even more
          enjoyable by letting your followers decide the winner for you. It
          works in 2 stages: First, run a simple photo contest, as we described
          above, to create a winner shortlist. Then once everyone has shared
          their photos, you can create a second contest, asking people to vote
          for the winner.
        </p>
        <button className="cta">
          <img src={carbonIcon} alt="" srcSet="" />
          <span>Start a contest</span>
        </button>
      </div>
    </IdeasStyled>
  );
};

export default Ideas;

const IdeasStyled = styled.section`
  ${({ location }) =>
    location === "/about/"
      ? css`
          > .ideaBx .title {
            color: #333333;
          }
        `
      : css`
          > .ideaBx .title {
            color: #1d1d63;
          }
        `}

  background: linear-gradient(90deg, #FFEFBA 0%, #FFFFFF 100%);
  display: flex;
  padding-left: 19%;

  .idea-img {
    align-self: flex-end;
    margin-right: 50px;
  }

  .ideaBx {
    align-self: center;
  }

  .ideaBx .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: -0.04em;
  }

  .ideaBx .content {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: #1d1d63;
    margin-top: 15px;
    max-width: 500px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ideaBx .cta {
    background-color: #f78f08;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 4px 20px rgba(241, 155, 0, 0.2);
    border-radius: 4px;
    margin-top: 20px;
  }

  .ideaBx .cta span {
    color: white;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    line-height: 19px;
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 10px;
    padding: 70px 20px 40px;
    .idea-img {
      display: none;
    }

    .ideaBx {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
