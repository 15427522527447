// Judge.Add.tsx
import { connectToStore } from "../../lib";
import { Add } from "../../components/Judge";

function JudgeCreationPage() {
  return (
    <>
      <h2>Add a new Judge</h2>
      <Add />
    </>
  );
}
export default connectToStore(JudgeCreationPage);
