// default.ts
import { MantineTheme, DEFAULT_THEME } from "@mantine/core";

const fontFamily = `"outfit", sans-serif`;

const config: Partial<MantineTheme> = {
  ...DEFAULT_THEME,
  // Monospace font-family, used in Code, Kbd and Prism components
  fontFamily,
  dir: "ltr",
  headings: {
    ...DEFAULT_THEME?.headings,      
    fontFamily
  },
  components: {
    Container: {
      defaultProps: {
        sizes: {
          xs: 540,
          sm: 720,
          md: 960,
          lg: 1140,
          xl: 1660,
        },
      },
    },
  },
  colors: {
    ...DEFAULT_THEME?.colors,
    brand: [
      "#E4C49B",
      "#E2B984",
      "#E2AF6C",
      "#E4A654",
      "#E89D3B",
      "#EF9622",
      "#F78F08",
      "#DD8410",
      "#C67A17",
      "#B1701C",
    ],
    dark: [
      "#d5d7e0",
      "#acaebf",
      "#8c8fa3",
      "#666980",
      "#4d4f66",
      "#34354a",
      "#2b2c3d",
      "#1d1e30",
      "#0c0d21",
      "#01010a",
    ],
  },
  primaryColor: "brand",
};
export default config;
