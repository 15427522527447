// General.jsx
import { connectToStore } from "../../lib";
import Card from "../Card";
import { Switch } from "@mantine/core";
import useSetting from "../../hook/useSetting";

function General({ SettingState, SettingDispatch }) {
  const { colorScheme, onToggleColorScheme } = useSetting();

  return (
    <>
      <Card title={"General"}>
        {/*        <div className="mb-3">
          {" "}
          <div className="flex justify-between gap-x-[8px]">
            <div>
              <p>Live mode</p>

              <small className="content text-xs text-gray-500 leading-tight">
                You can test out transactions, forms, events in test mode. In
                this mode, no real transactions are made. When you are ready,
                you can switch back to live mode to process real data
              </small>
            </div>
            <div className="shrink-0">
              <Switch
                checked={SettingState?.is_live}
                onChange={() =>
                  SettingDispatch.$SET({
                    ...SettingState,
                    is_live: !SettingState?.is_live,
                  })
                }
              />
            </div>
          </div>
        </div>*/}

        <div className="mb-3 flex justify-between gap-x-[8px]">
          <span>Enable push notification</span>
          <Switch
            onLabel="ON"
            offLabel="OFF"
            size="md"
            checked={SettingState?.is_push_notification_enabled}
            onChange={() =>
              SettingDispatch.$SET({
                ...SettingState,
                is_push_notification_enabled:
                  !SettingState?.is_push_notification_enabled,
              })
            }
          />
        </div>

        <div className="mb-3 flex justify-between gap-x-[8px]">
          <span>Enable network caching</span>
          <Switch
            checked={SettingState?.is_network_caching_enabled}
            onLabel="ON"
            offLabel="OFF"
            size="md"
            onChange={() =>
              SettingDispatch.$SET({
                ...SettingState,
                is_network_caching_enabled:
                  !SettingState?.is_network_caching_enabled,
              })
            }
          />
        </div>

        <div className="mb-3 flex justify-between gap-x-[8px]">
          <div className="grow">
            <p>Dark mode</p>
            <small className="content text-xs text-gray-500 leading-tight">
              Change your appearance to dark mode
            </small>
          </div>
          <Switch
            checked={colorScheme?.colorScheme == "dark"}
            onLabel="ON"
            offLabel="OFF"
            size="md"
            onChange={onToggleColorScheme}
          />
        </div>
        {/*<div>
        <span>Theme</span>
      </div>*/}
      </Card>
    </>
  );
}

export default connectToStore(General);
