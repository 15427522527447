import styled from "styled-components";
import { DeleteIcon, SearchIcon } from "../Svgs";
import { BankAccountList } from ".";
import Card from "../Card";
import ListCard from "../ListCard";
import { Button, Textarea, TextInput, Select } from "@mantine/core";

const BankInformation = () => {
  return (
    <BankInformationStyled>
      <div>
        <Card title={"Add account information"}>
          <div className="mb-4">
            <TextInput label="Account name" />
            <TextInput label="Account number" />
            <TextInput label="Email address" />
            <TextInput label="Bank name" />
            <TextInput label="Mobile number" />
          </div>
          <Button className="w-full" size="md">
            Add account
          </Button>
        </Card>
      </div>

      <div className="col col-2">
        <ListCard list={[]}>
          {({ list }) => (
            <BankAccountList
              bankName={"Royalty Bank of Africa"}
              accountNumber={"2l333jlxxxx"}
            />
          )}
        </ListCard>
      </div>
    </BankInformationStyled>
  );
};

export default BankInformation;

const BankInformationStyled = styled.section`
  display: flex;
  gap: 0 30px;

  .col-2 {
    flex-grow: 1;

    .actions {
      position: relative;
      margin: 15px;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        padding: 10px 12px 10px 34px;
        background: #f6f6f6;
        border-radius: 4px;
        border: none;
        width: 349px;
      }
      > svg {
        position: absolute;
        top: 11px;
        left: 13px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    .col-2 {
      width: 100%;
    }
  }
`;
