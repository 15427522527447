import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "auth");
    this._name = "Auth";
    return this;
  }

  /**
   * @description Send request to log into a user's account
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.email
   * @param {string} args.data.password
   * @returns {Promise<import("../types").APIResponse.Login>}
   */
  async createToken({ data }) {
    const res = await this.axios(
      {
        url: "/create/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  // START: SOCIAL LOGIN
  /**
   * @description Get google URL
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.email
   * @param {string} args.data.password
   * @returns
   */
  async getGoogleURL() {
    const host = window.location.hostname;
    const res = await this.axios(
      {
        url: `/o/google/?redirect_url=${host}`,
        method: "post",
      },
      true
    );
    return res.data;
  }

  /**
   * @description Get google token
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.code
   * @param {string} args.data.stale
   * @returns
   */
  async getGoogleToken({ data }) {
    const res = await this.axios(
      {
        url: "/o/Google/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }
  // END: SOCIAL LOGIN

  /**
   * @function refreshToken - Refresh auth token
   * @description Send request to log into a user's account
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.refresh
   * @returns
   */
  async refreshToken({ data }) {
    const res = await this.axios(
      {
        url: "/refresh/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function verifyToken - Verify auth token
   * @description Send request to log into a user's account
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.token
   * @returns
   */
  async verifyToken({ data }) {
    const res = await this.axios(
      {
        url: "/verify/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }
}
