import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, TextInput, Container, Stack, Text } from "@mantine/core";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import useApi from "../../hook/useApi";
import * as Yup from "yup";
import { styles, COLORS } from "../../config";
import { connect } from "react-redux";
import { routeMap } from "../../route-map";
import toast from "react-hot-toast";
import { parseErrorMessage,patterns, connectToStore } from "../../lib";
import { useForm, yupResolver,  } from "@mantine/form";
import Logo from "../../components/Logo";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Email address is required"),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(patterns?.password, "Password must be alphanumeric and must contain at least a capital-case character")
    .required("Password is required"),
});

const Login = ({ SessionDispatch }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const api = useApi();

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function loginWithPassword(data) {
    toast.loading("Authenticating...", {
      id: "login-user-loading",
    });
    try {
      isMounted.current && setLoading(true);
      const res = await api.Auth.createToken({ data });

      toast.success("Welcome back", {
        id: "login-user-success",
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      });

      SessionDispatch.$SET({
        isLoggedIn: true,
        email: data?.email,
        ...res,
      });
    } catch (err) {
      parseErrorMessage(err, false, "login with password");
    } finally {
      toast.dismiss("login-user-loading");
      isMounted.current && setLoading(false);
    }
  }

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(validationSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  return (
    <Container size="sm" px="lg" >
      <Stack spacing={20} sx={{ height: "100vh", justifyContent:"center", }} >
        <Stack spacing="lg" className="items-center flex flex-col text-center gap-[10px] my-[20px]">
          <div className="mb-4">
            <Logo style={{ width: 45, height: 45 }} />
          </div>
          <h2 className="title">Welcome back!</h2>
          <Text className="subtitle">
            We missed you! Sign in to continue from <br /> where you stopped
          </Text>
        </Stack>
        <form
          onSubmit={form.onSubmit(loginWithPassword)}
          className="mx-auto  my-[20px] flex flex-col gap-y-[20px] justify-center"
        >
          <TextInput
            size="md"
            type="email"
            placeholder="Email address"
            label="Email address"
            name="email"
            id="email"
            {...form.getInputProps("email")}
          />
          <TextInput
            label="Password"
            size="md"
            placeholder="Password"
            defaultValue={form.values.password}
            type={showPassword ? "text" : "password"}
            id="password"
            {...form.getInputProps("password")}
            rightSection={
              <span
                style={{
                  cursor: "pointer",
                  display: "inline-flex",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <IoEye></IoEye> : <IoEyeOff></IoEyeOff>}
              </span>
            }
          />

          <Button
            disabled={!form.isValid()}
            type="submit"
            size="md"
            className="w-full"
            loading={loading}
          >
            Sign in
          </Button>

          <Text className="my-2 text-center">
            Don't have an account?{" "}
            <Text color="brand" component={Link} to={routeMap?.auth?.register}>
              Sign Up
            </Text>
          </Text>
        </form>
      </Stack>
    </Container>
  );
};

export default connectToStore(Login);

const LoginStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    width: 100%;
  }

  .header {
    height: 100%;
    flex: 1;
    flex-direction: column;
    display: flex;
  }

  .form-container {
    padding: 30px;
    flex-grow: 1;
    width: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .form-container {
    flex-grow: 1;
    max-width: 400px;
    p {
      margin-top: 30px;
    }
    .orange {
      color: #f78f08;
      display: inline;
    }
  }
  .title {
    font-weight: 700;
    font-size: 35px;
    line-height: auto;
    text-transform: uppercase;
  }
  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /*color: ${({ theme }) => theme?.colors?.text?.label};*/
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    .contentWrapper {
      flex-direction: column;
      align-items: center;
    }
  }
`;
