// styles.js
import styled from "styled-components";
import COLORS from "./colors";

export default {
  form: {
    label: styled.label`
      color: ${COLORS?.text?.contrast};
    `,
  },
};
