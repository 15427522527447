// contest.js

import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "contests");
    this._name = "contests";
    return this;
  }

  /**
   * @function create - create contest
   * @param {object} args
   * @param {object} args.logo
   * @param {string} args.logo.url
   * @param {object[{url: string}]} args.gallery
   * @param {string} args.name
   * @param {string} args.description
   * @param {string} args.slug
   * @param {string} args.start_at
   * @param {string} args.end_at
   * @param {string} args.location
   * @param {string} args.status
   * @param {number} args.elimination_limit
   * @param {boolean} args.public
   * */
  async create({ data }) {
    const res = await this.axios(
      {
        url: "/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function update
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.id
   *
   */
  async update({ data, id }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "patch",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function retrieve
   * @param {object} args
   * @param {string} args.id
   *
   */
  async retrieve({ id }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async blogs({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/blogs/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async results({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/results/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async eliminations({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/eliminations/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async prizes({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/prizes/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async gallery({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/gallery/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async forms({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/forms/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  async judges({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/judges/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @param {object} args
   * @param {object} args.params
   * @param {string} args.params.owner
   * @returns {Promise}
   */
  async list(args = { param: undefined }) {
    const stringParam = new URLSearchParams(args?.param);

    const res = await this.axios(
      {
        url: `/?${stringParam}`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @function vote
   * @param {object} args
   * @param {object} args.data
   * @param {number | string} args.data.quantity
   * @param {number | string} args.data.price
   * @param {string} args.data.email
   * @param {string} args.data.gateway
   *
   */
  async vote({ data, id }) {
    const res = await this.axios(
      {
        url: `/vote/${id}/`,
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function remove
   * @param {object} args
   * @param {string} id
   */
  async remove({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/`,
        method: "delete",
      },
      true
    );
    return res.data;
  }

  /**
   * @function bulkRemove
   * @param {object} args
   * @param {string[]} ids
   */
  async bulkRemove({ ids }) {
    const res = await this.axios(
      {
        url: `/?${ids?.map((id) => `ids[]=${id}`)?.join("&")}`,
        method: "delete",
      },
      true
    );
    return res.data;
  }
}
