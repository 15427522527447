// draft.js
import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {import("../../types").Reducer.Session} defaultState
 */
const initialState = {
  event: [],
  activity: [],
  ticket: [],
  form: [],
  judge: [],
  contestants: [],
  gallery: [],
};

export const draftSlice = createSlice({
  name: "draft",
  initialState,
  reducers: {
    $SET: (state, action) => {
      return { state, ...action.payload };
    },
    $CLEAR: (state, action) => {
      return initialState;
    },
  },
});

export const actions = draftSlice.actions;
export default draftSlice.reducer;
