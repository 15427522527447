import { useDispatch, useSelector } from "react-redux";

// Use throughout your app instead of plain `useDispatch` and `useSelector`

export const useAppDispatch = () => useDispatch();

export const useAppSelector = useSelector;

const useRedux = {
  useAppDispatch,
  useAppSelector,
};

export default useRedux;
