import { useState } from "react";
import styled from "styled-components";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import {Paper} from "@mantine/core"

const Card = ({
  children,
  title = null,
  fullWidth = false,
  fullHeight = false,
  rightSection = null,
  expand = true,
}) => {
  const [expanded, setExpanded] = useState(expand);

  function toggleExpansion() {
    setExpanded(!expanded);
  }

  return (
    <CardStyled>
      {title && (
        <div className="header">
          <h3>{title}</h3>
          <div>
            {rightSection && rightSection}

            <span onClick={toggleExpansion} className="collapsible-btn">
              {expanded ? <BsFillCaretUpFill /> : <BsFillCaretDownFill />}
            </span>
          </div>
        </div>
      )}
      <div className={`body ${expanded && children ? "" : "hidden"}`}>
        {children}
      </div>
    </CardStyled>
  );
};

export default Card;

const CardStyled = styled(Paper)`
  width: 100%;
  min-width: 350px;
  flex-grow: 1;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
  padding: 8px;
  background: ${({ theme }) =>
    theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.colors.background[0]};
  border-radius: 4px;
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .collapsible-btn {
    cursor: pointer;
  }

  .rightBtn {
    background: #f78f08;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(241, 155, 0, 0.2);
    border: none;
    border-radius: 4px;
    margin-right: 11px;
    padding: 8px 10px;
    svg {
      margin-right: 9px;
    }
    span {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 14px;
      color: #ffffff;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 4px 8px;
    user-select: none;

    > div {
      display: flex;
      align-items: center;
    }
    p {
      font-weight: 500;
      font-size: 1.15rem;
      line-height: 24px;
      color: ${({ theme }) =>
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.dark[1]};
    }
    svg,
    img {
      width: 14px;
      height: 14px;
    }
  }
  .body {
    padding: 15px;
    margin: 4px;
    border-radius: inherit;
    border: 1px solid
      ${({ theme }) =>
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.background[3]};
  }
`;
