// Activity.Create.tsx
import { connectToStore } from "../../lib";
import { Add } from "../../components/Activity";
import {Container, Title, Stack} from "@mantine/core"

function ActivityCreationPage() {
  return (
     <Stack sx={{maxWidth:950}}>
      <Title order={2}>Add activity</Title>
      <Add />
     </Stack> 
  );
}
export default connectToStore(ActivityCreationPage);
