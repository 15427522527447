// Fileinput.jsx
import { useState, useRef, useEffect, memo, useMemo } from "react";
import { connectToStore, returnFileSize, parseErrorMessage } from "../../lib";
import { useToggle, useApi } from "../../hook";
import styled from "styled-components";
import {
  FaInfoCircle,
  FaCloudUploadAlt,
  FaCaretUp,
  FaCaretDown,
} from "react-icons/fa";
import { SimpleGrid, Loader, Text } from "@mantine/core";
import { BsTrashFill, BsLayoutWtf } from "react-icons/bs";
import toast from "react-hot-toast";
import { Modal, Button, SegmentedControl, Transition } from "@mantine/core";


const gallerySection = [
                  { label: "Image", value: "image" },
                  { label: "Video", value: "video" },
                ];

export const FileUploader = ({ open, onUpload, inputProps }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dropbox = useRef();
  const isMounted = useRef(true);
  const api = useApi();

  useEffect(() => {
    if (!isMounted?.current) isMounted.current = true;
    if (dropbox?.current) {
      dropbox?.current?.addEventListener("dragenter", dragenter, false);
      dropbox?.current?.addEventListener("dragover", dragover, false);
      dropbox?.current?.addEventListener("drop", drop, false);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  function dragenter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  function dragover(e) {
    e.stopPropagation();
    e.preventDefault();
    dropbox.current?.classList.add("dragover");
  }
  function drop(e) {
    e.stopPropagation();
    e.preventDefault();

    const dt = e.dataTransfer;
    const newFiles = Object.values(dt.files);
    let list = [...files, ...newFiles];
    isMounted?.current && setFiles(list);
    dropbox?.current?.classList.remove("dragover");
  }

  async function uploadFiles() {
    try {
      toast.loading("Uploading...", {
        id: "asset-upload-loading",
      });
      isMounted?.current && setLoading(true);
      const fd = new FormData();

      files.forEach((file) => fd.append("file", file));

      const response = await api.Upload.create({ data: fd });
      toast.success("Operation complete", {
        id: "asset-upload-success",
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      });
      isMounted?.current && setFiles([]);
      if (onUpload && typeof onUpload == "function") onUpload();
    } catch (error) {
      parseErrorMessage(error, false, "upload asset");
    } finally {
      toast.dismiss("asset-upload-loading");
      isMounted?.current && setLoading(false);
    }
  }

  return (
    <ImageUploadStyled ref={dropbox} className={open ? "is-open" : "is-hidden"}>
      {files?.length ? (
        <div className="flex flex-wrap w-full gap-x-[8px]">
          {files?.map((file, index) => {
            //TODO: Check if the file type is valid
            const src = URL.createObjectURL(file);
            return (
              <div className={`file`} key={index}>
                <img
                  title={file.name}
                  alt={file.name}
                  src={src}
                  onLoad={() => {
                    URL.revokeObjectURL(src);
                  }}
                />
                <div className="meta">
                  <span className="truncate opacity-[.5] text-white">
                    {returnFileSize(file.size)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="upload-container">
        <label
          htmlFor="browse-file"
          className="items-center justify-center flex flex-col p-4 px-8 grow "
        >
          <FaCloudUploadAlt style={{ fontSize: 45 }} />
          <p className="upload">Browse file or drag and drop files here</p>
        </label>
        <input
          type="file"
          allow="image/*,video/*"
          name="browse-file"
          multiple
          disabled={loading}
          id="browse-file"
          defaultValue={files}
          onChange={({ target }) => {
            isMounted?.current && setFiles(Object.values(target?.files));
          }}
          {...inputProps}
        />
        {files?.length ? (
          <Button loading={loading} className={"mt-4"} onClick={uploadFiles}>
            Upload
          </Button>
        ) : null}
      </div>
    </ImageUploadStyled>
  );
};

/**
 * @param {object} props
 * @param {Function} props.makeSelection
 * @param {'image'|'video'} props.type
 * @param {object} props.data
 * @param {string} props.data.file
 * @param {string} props.data.thumbnail
 * @param {string} props.data.livemode
 * @param {string} props.data.id
 * @return {React.FC}
 */
export const AssetFrame = memo(
  ({
    data,
    onReload = () => null,
    onSelect,
    type = "image",
    selectable = true,
    selected,
  }) => {
    const [isSelected, setIsSelected] = useState(selected);
    const isMounted = useRef(true);
    const api = useApi();

    /**
     * @function removeAsset - delete uploaded asset
     * @param {string} id - asset ID
     */
    async function removeItem(id) {
      try {
        toast.loading("Working...", {
          id: "delete-single-upload-loading",
        });
        await api.Upload.remove({ id });
        toast.success("Operation complete", {
          id: "remove-single-upload-success",
          style: {
            minWidth: "250px",
          },
          success: {
            duration: 5000,
          },
        });
        // remove from selection
        onSelect(data, -1);
      } catch (error) {
        parseErrorMessage(error, false, "remove asset");
      } finally {
        toast.dismiss("delete-single-upload-loading");
        if (onReload && typeof onReload == "function") {
          onReload();
        }
      }
    }

    useEffect(() => {
      if (!isMounted?.current) isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      setIsSelected(selected);
    }, [selected]);

    function toggleSelection() {
      if (selectable) {
        onSelect(data, !isSelected ? 1 : -1);
        setIsSelected(!isSelected);
      }
    }

    if (!("id" in data) || !("file" in data)) {
      throw new Error("ID or file missing in data");
    }

    function SwitchByType(type) {
      switch (type) {
        case "image": {
          return (
            <>
              <img id={data?.id} src={data?.file} />
            </>
          );
        }
        default:
          return null;
      }
    }

    return (
      <div
        onClick={toggleSelection}
        className={["asset-frame", isSelected ? "is-selected" : undefined].join(
          " "
        )}
      >
        {!isSelected ? null : (
          <Button
            color={"red"}
            size="xs"
            onClick={() => removeItem(data?.id)}
            className="right-0 z-[2] items-center justify-center text-[20px] absolute"
          >
            <BsTrashFill style={{ fontSize: 14 }} />
          </Button>
        )}
        {SwitchByType(type)}
      </div>
    );
  }
);

/**
 * @param {object} props
 * @param {object} props.onReload
 */
export function NothingFound({ onReload }) {
  return (
    <section className="flex flex-col items-center justify-center grow h-full">
      <div className="p-4 text-center flex flex-col gap-y-[8px] items-center">
        <BsLayoutWtf size={100} className="opacity-[.35]"/>
        <p className="opacity-[.5]">Nothing Found</p>
      </div>
      <Button size="md">Reload</Button>
    </section>
  );
}

/**
 * @param {React.FC<{data: any[]; filter: Record<string, any>}>} props
 */
export function ListFilter({ data = [], filter, children }) {
  return children({ data });
}

function useUploadManager(extraProps) {
  const api = useApi();
  const isMounted = useRef(true);
  const modal = useToggle();
  const uploader = useToggle();
  const [assetType, setAssetType] = useState(gallerySection[0]?.value);
  const [selected, setSelected] = useState({});
  const [assets, setAssets] = useState({
    loading: false,
    data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  });

  function addToSelection(id, data) {
    isMounted.current && setSelected({ ...selected, [id]: data });
  }

  function setSelection(id, data) {
    isMounted.current &&
      setSelected({
        [id]: data,
      });
  }

  function resetSelection(id, data) {
    isMounted.current && setSelected({});
  }

  function removeFromSelection(id) {
    const temp = Object.assign({}, selected);
    delete temp[id];
    isMounted.current && setSelected(temp);
  }

  /**resetSelection
   * @description fetches the upload assets from the server and sets the asset state
   */
  async function fetchAssets() {
    try {
      isMounted?.current && setAssets((prev) => ({ ...prev, loading: true }));
      const data = await api.Upload.list();
      isMounted?.current && setAssets((prev) => ({ ...prev, data }));
    } catch (error) {
      console.error(error);
    } finally {
      isMounted?.current && setAssets((prev) => ({ ...prev, loading: false }));
    }
  }

  useEffect(() => {
    if (!isMounted?.current) isMounted.current = true;
    // TODO: Add a modal persistent selection setting
    // When you do, make the following statement, conditional
    (async () => {
      await fetchAssets();
    })();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    modal,
    uploader,
    selected,
    setSelected,
    assetType,
    setAssetType,
    assets,
    setAssets,
    addToSelection,
    setSelection,
    removeFromSelection,
    resetSelection,
    fetch: fetchAssets,
    ...extraProps,
  };
}

/**
 * @param {object} props
 * @param {object} props.onFinish
 * @param {object} props.inputProps - File input props. Accepts any valid html file input props
 * @param {object} props.label
 */
function UploadManager({
  label,
  children,
  max,
  size="sm",
  onFinish = () => null,
  inputProps,
}) {
  const api = useApi();

  const [prevSelected, setPrevSelected] = useState([]);
  const [length, setLength] = useState(0);
  const isMounted = useRef(true);

  const gallery = useUploadManager();

  useEffect(() => {
    setLength(Object.values(gallery.selected)?.length);
  }, [gallery?.selected]);

  /**
   * @param {any} itemId
   * @param {1|-1} type - 1 means add, -1 means remove
   * @return {void}
   */
  function onSelect(data, type = 1) {
    if (type == -1) {
      // remove
      gallery?.removeFromSelection(data?.id);
    } else {
      // Add
      if (max && length == max) {
        gallery?.setSelection(data?.id, data);
      } else gallery?.addToSelection(data?.id, data);
    }
  }

  useEffect(() => {
    // if(isOpen)
    gallery?.resetSelection();
  }, [gallery?.modal?.isOpen]);

  return (
    <section >
      <div className="flex flex-col">
        <Text size={size} className="">{label}</Text>
        {children ? (
          children(gallery?.prevSelected)
        ) : (
          <div className="flex flex-wrap gap-x-[4px]">
            {Object.values(prevSelected)
              ?.slice(0, 1)
              ?.map((item, index) => (
                <div
                  key={index}
                  className="basis-1/2 grow max-h-[75px] overflow-hidden"
                >
                  <img
                    src={item?.file}
                    className="max-w-[100%]  object-cover"
                  />
                </div>
              ))}
          </div>
        )}
        <Button
          color={"gray"}
          variant="outline"
          style={{ width: "100%" }}
          type="button"
          size={size} 
          onClick={gallery?.modal?.onOpen}
        >
          Browse
        </Button>
        <div className="flex justify-between items-center mt-1">
          <small className="block opacity-[.5]">
            Selected {length} file(s)
          </small>
          <small>{max ? `Max: ${max}` : null}</small>
        </div>
      </div>

      <Transition mounted={gallery?.modal?.isOpen} transition="scale-y" duration={400} timingFunction="ease">
        {(style) => <Modal
          style={style}
        opened={gallery?.modal?.isOpen}
        onClose={gallery?.modal?.onClose}
        size="100%"
        title={"Upload manager"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        <StyledModalContainer>
          {/*<section className="modal-aside">
            <ul className="nav-list grow">
              <li 
              className={["list-item cursor-pointer", assetType == 'image' ? "is-active": undefined].join(" ")} 
              onClick={() => setAssetType("image")}>Image</li>
              <li 
              className={["list-item cursor-pointer", assetType == 'video' ? "is-active": undefined].join(" ")} 
              onClick={() => setAssetType("video")}>Video</li>
            </ul>            
          </section>*/}
          
            <StyledHeader>
              {gallery?.assets?.loading ? (
            <Loader/>
          ) : gallery?.assets?.data?.count ? (<SegmentedControl
                value={gallery?.assetType}
                onChange={gallery?.setAssetType}
                data={gallerySection}
              />) : null}

              <div className="">
                <Button
                  variant="default"
                  size="xs"
                  title="Toggle upload area"
                  onClick={gallery?.uploader?.onToggle}
                >
                  {gallery?.uploader?.isOpen
                    ? "Hide section"
                    : "Click to upload"}
                  {gallery?.uploader?.isOpen ? <FaCaretDown /> : <FaCaretUp />}
                </Button>
              </div>
            </StyledHeader>
          
          <section className="modal-main">
            {!gallery?.assets?.data?.count ? (
              <NothingFound />
            ) : (
              <section className="body">
                {/*<section className="asset-grid">*/}
                <SimpleGrid
                  cols={6}
                  spacing="md"
                  breakpoints={[
                    { maxWidth: "md", cols: 3, spacing: "md" },
                    { maxWidth: "sm", cols: 2, spacing: "sm" },
                    { maxWidth: "xs", cols: 2, spacing: "sm" },
                  ]}
                >
                  <ListFilter
                    data={gallery?.assets?.data?.results}
                    filter={{ mime: "image" }}
                  >
                    {({ data }) =>
                      data?.map((item) => (
                        <AssetFrame
                          key={item?.id}
                          type={gallery?.assetType}
                          data={item}
                          selected={Boolean(gallery?.selected[item?.id])}
                          onSelect={onSelect}
                          onReload={gallery?.fetch}
                        />
                      ))
                    }
                  </ListFilter>
                </SimpleGrid>
              </section>
            )}

            {/*UPLOADER*/}
            <section className=" z-[2]">
              <FileUploader
                inputProps={inputProps}
                open={gallery?.uploader?.isOpen}
                onUpload={gallery?.fetch}
              />
            </section>
          </section>

          {/*FOOTER*/}
          <section className="footer ">
            <div className="flex items-center justify-between">
              <div className="">
                <p>
                  You have selected: {Object.values(gallery?.selected)?.length}{" "}
                  out of {max ?? gallery?.assets?.data?.count}
                </p>
              </div>
              <div className="ml-auto">
                <Button
                  size="md"
                  style={{}}
                  disabled={!Object.keys(gallery?.selected)?.length}
                  onClick={() => {
                    if (onFinish && typeof onFinish == "function")
                      isMounted?.current && onFinish(gallery?.selected);
                    isMounted?.current && setPrevSelected(gallery?.selected);
                    gallery.modal.onClose();
                  }}
                >
                  Finish
                </Button>
              </div>
            </div>
          </section>
        </StyledModalContainer>
      </Modal>}
      </Transition>
    </section>
  );
}

export default connectToStore(UploadManager);


const StyledHeader = styled.section`
  margin-bottom: 8px;
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;  
  }

`;

const StyledModalContainer = styled.section`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  position: relative;

  .asset-frame {
    height: 150px;
    flex-grow: 0;
    position: relative;
    border-radius: 8px;

    &.is-selected {
      outline: 2px solid ${({ theme }) => theme.colors.brand[5]};
      outline-offset: -4px;
      img {
        transform: scaleY(0.85) scaleX(0.9);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      position: relative;
      transition: transform 0.25s ease-in;
      z-index: 1;
    }
  }
  .modal-main {
    flex-grow: 1;
    position: relative;
    margin-bottom: 16px;
    overflow-y: auto;
    z-index: 1;
    .body {
      flex-grow: 1;
      max-height: 100%;
      overflow-y: auto;
      .asset-grid {
        display: flex;
        flex-wrap: wrap;
        // min-height: 100vh;
        column-gap: 8px;
        row-gap: 8px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 10px;

    .asset-frame {
      // width: ${100 / 2.09}%;
    }
    .modal-aside {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      .nav-list {
        display: flex;
      }
    }
    .modal-main {
      width: 100%;
    }
  }
`;

const ImageUploadStyled = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;width: 100%;
  user-select: none;
  overflow: hidden;
  width: 100%;
  height: 300px;
  bottom: 0;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction:row;  
  // background: #eaeaea;
  column-gap: 16px;
  row-gap: 16px;
  // border-top: 1px solid #dcdcdc;
  transition: all .5s ease-in;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
  }
     
  padding: 10px;
   background: ${({ theme }) =>
     theme.colorScheme === "dark"
       ? theme.colors.dark[7]
       : theme.colors.background[0]};
  &.is-hidden{
    // margin-top: 200px;
    height: 0;
    padding: 0;
    // opacity: 0;
    // bottom: -300px;
  }

  &.dragover{
    opacity: .5;
    outline: 2px dashed purple;
    outline-offset: -4px;
  }
  input {
    display: none;
    opacity: 0;
    height: 0;    
  }
  
  label {
    text-align: center;
  }
  .upload-container {
    min-width: 300px;
    flex: auto;    
    display: flex;
    overflow: hidden;
    user-select: none;
    flex-direction: column;
    label{
      padding: 30px 60px;
     
      &:hover{
      background: ${({ theme }) =>
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.background[2]};
      }
  
    }
    @media screen and (max-width: 768px) {
      min-width: 70%;
      position: relative;
    }
  }
  
  .upload {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #8c8c8c;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .file {    
    height: 170px;
    width: 100px;
      overflow:hidden;
      margin-bottom: 8px;
    flex-grow: 1;
    position: relative;
    border-radius: 8px;
    border: 2px solid #333;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
    .meta{
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 8px;
      width: 100%;
      opacity: 0;
      transform: translateY(100%);
      transition: all .5s ease-in-out;
      color: #fff
      z-index: 2;
    }
    &:hover .meta {
      opacity: 1;
      transform: translateY(0)
    }
    image{
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1
    }
  }
`;
