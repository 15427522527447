// Form.Add.tsx
import { connectToStore } from "../../lib";
import { Add } from "../../components/Form";

function FormCreationPage() {
  return (
    <>
      <h2>Create a new Form</h2>
      <Add />
    </>
  );
}
export default connectToStore(FormCreationPage);
