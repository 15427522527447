// Security.jsx
import { connectToStore } from "../../lib";
import Card from "../Card";
import { Switch } from "@mantine/core";

function Security({ SettingState, SettingDispatch }) {
  function toggle2FA() {
    try {
      if (!SettingState?.is_2fa_enabled) {
        // Show 2FA interface
      }
      SettingDispatch?.$SET({
        ...SettingState,
        is_2fa_enabled: !SettingState?.is_2fa_enabled,
      });
    } catch (err) {
      console.error("ERR::Setting.Security.toggle2FA", err);
    }
  }

  return (
    <>
      <Card title={"Security"}>
        <div className="flex justify-between gap-x-[8px]">
          <div className="grow">
            <p>Enable 2FA</p>
            <small className="content text-xs text-gray-500 leading-tight">
              Enable 2 factor authentication
            </small>
          </div>
          <div className="shrink-0">
            <Switch
              onLabel="ON"
              offLabel="OFF"
              size="md"
              checked={SettingState?.is_2fa_enabled}
              onChange={toggle2FA}
            />
          </div>
        </div>
      </Card>
    </>
  );
}

export default connectToStore(Security);
