import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connectToStore } from "../../lib";
import SelectorModal from "../Selector";
import { useToggle } from "../../hook";
import { Button, TextInput, Text } from "@mantine/core";
import { BsFillCalendar2DateFill, BsSearch } from "react-icons/bs";
import { routeMap } from "../../route-map";
import { useNavigate } from "react-router-dom";
import { faker } from "@faker-js/faker";

const Header = () => {
  return <h3 className="mr-auto">Select event</h3>;
};

const dummy = Array(5)?.fill()?.map((item, index) => ({id: faker.datatype.uuid()}));

const Body = ({ setEvent, event }) => {
  return (
    <>
      <div className="mb-4 sticky left-0 top-0 w-full">
        <TextInput
          placeholder="Search by name"
          type="search"
          className="w-full"
          icon={<BsSearch />}
        />
      </div>
      <div className="body ">
        <ul className="list-container px-2">
          {dummy
            ?.map((item, index) => {
              return <div
                onClick={() => setEvent(item)}
                key={item?.id}
                className={`min-h-[40px] list-item mb-2 ${
                  event?.id == item?.id ? "selected" : "not-selected"
                }`}
              >
                <Text>{item?.id}</Text>
              </div>
            })}
        </ul>
        <div className="my-2">
          <Button variant="default" className="w-full">
            Load more
          </Button>
        </div>
      </div>
    </>
  );
};

const Footer = ({ modal, event, onFinish, setSelected, size }) => {
  const navigate = useNavigate();
  return (
    <footer>
      <Button
        variant="outline"
        onClick={() => navigate(routeMap?.account?.event_add)}
      >
        Add New
      </Button>
      <Button onClick={() => {
        size={size}
        onFinish && onFinish(event)
        modal?.onClose()
        setSelected(event);
      }}>
        Continue
      </Button>
    </footer>
  );
};

const EntryButton = ({ modal, selected, placeholder, ButtonStyle, size }) => {
  return (
    <div
      style={
        selected
          ? {
              outline: "1px solid #88888855",
              outlineOffset: 4,
              borderRadius: 4,
            }
          : null
      }
    >
      <Button
        color={"gray"}
        variant="outline"
        style={{ width: "100%", ...ButtonStyle }}
        type="button"
        leftIcon={<BsFillCalendar2DateFill />}
        onClick={modal?.onToggle}
        size={size}
      >
        {placeholder}
      </Button>

      {selected ? (
        <div className="flex p-[8px] text-left  grow w-full">
          <div className="truncate">
            <small className="block opacity-[.5]">Selected Event</small>
            <p className="truncate" title={JSON.stringify(selected)}>
              {JSON.stringify(selected)}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function EventSelector({onFinish, size}) {
  const [event, setEvent] = useState("");
  const modal = useToggle();

  return (
    <SelectorModal
      size={size}
      renderHeader={Header}
      renderBody={Body}
      renderFooter={Footer}
      renderButton={EntryButton}
      placeholder={"Pick an Event"}
      onFinish={onFinish}
    />
  );
}

function Renderer({ children, ...rest }) {
  return children(rest);
}

export default connectToStore(EventSelector);
