// event.js
import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "events");
    this._name = "Event";
    return this;
  }

  /**
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.name
   * @param {string} args.data.description
   * @param {string | object} args.data.image
   * @param {string} args.data.slug
   * @param {string} args.data.start_at
   * @param {string} args.data.end_at
   * @param {string[]} args.data.tags
   * @param {object} [args.data.settings]
   * @param {string} [args.data.settings.primary_color]
   * @param {string} [args.data.settings.secondary_color]
   * @param {boolean} [args.data.settings.livemode]
   * @param {"ACTIVE" | "CLOSED"} [args.data.status]
   * @param {object[]} [args.data.tickets]
   * @param {string[]} [args.data.contest]
   * @param {object[] | string[]} [args.data.gallery]
   * @param {boolean} [args.data.publish]
   * @param {object} [args.data.metadata]
   *
   */
  async create({ data }) {
    const res = await this.axios(
      {
        url: "/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @param {obejct} args
   * @param {obejct} args.param
   * @param {string[]} args.param.ids
   * @param {boolean} args.param.publish
   * @param {string} args.param.name
   * @param {string} args.param.slug
   * @param {string} args.param.start_at
   * @param {string} args.param.end_at
   * @param {"ACTIVE" | "CLOSED"} args.param.status
   * @param {string | string} args.param.owner
   * @param {string | string} args.param.location
   * @param {string | string[]} args.param.tags_name
   * @param {string | string} args.param.created_at
   * @param {string | string} args.param.updated_at
   * @param {string | string} args.param.location_icontains
   * @return {Promise<{count: number, next: number | null, previous: null | number,results: object[]}>}
   */
  async list(args = { param: undefined }) {
    const stringParam = args?.param ? new URLSearchParams(args?.param) : "";

    const res = await this.axios(
      {
        url: `/?${stringParam}`,
        method: "get",
      },
      true
    );
    return res.data;
  }
}
