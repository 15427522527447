// ListContestant.jsx
import { useState } from "react";
import { connectToStore } from "../../lib";
import ListCard from "../ListCard";

function ListContestant() {
  const [list, setList] = useState([]);

  return <ListCard list={list}>{({ list }) => <></>}</ListCard>;
}

export default connectToStore(ListContestant);
