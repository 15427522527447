// DetailForm.jsx
import React from 'react';
import {BsLightbulb} from "react-icons/bs"
import {
  TextInput,
  Textarea,
  Select,
  Button,
  ColorInput,
  Tag,
  Text,
  DEFAULT_THEME,
  Group,
  Container,
  Grid, Code,
  Switch,
  Paper,
  Stack,
  MultiSelect,
  HoverCard,
  UnstyledButton
} from "@mantine/core";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { routeMap } from "../../route-map";
import { TimeInput, TimeRangeInput , DatePicker} from '@mantine/dates';
import { useForm, yupResolver,  } from "@mantine/form";
import { UploadManager } from "../FormElement";
import {connectToStore} from "../../lib";

const swatches = [];
Object.values(DEFAULT_THEME.colors)?.forEach(item => swatches.push(...item));

const validationSchema = Yup.object({
  name: Yup.string().
        min(5, "Name expects a minimum of 5 characters").required("Name field is required"),
  description: Yup.string().
        min(20, "Name expects a minimum of 20 characters").required("Description field is required")
});


function EventDetailForm (props) {
  const {DraftDispatch, DraftState, dispatchChange, dispatchError} = props;
	 const form = useForm({
    initialValues: {
      name: "",
      description: "",
      abbr: "",
      primary_color: "",
      accent_color: "",
      cover_image:null,
      keywords: []
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(validationSchema),    
  });
   const isMounted = React.useRef(true);

React.useEffect(() => {
  if(isMounted.current )
    isMounted.current = true;

  return () => {
    isMounted.current = false
  }
}, []);

React.useEffect(() => {
  dispatchChange(form?.values)
}, [form?.values]);

React.useEffect(() => {
  const errors = Object.values(form.errors);
  if(errors?.length)
    dispatchError(form?.errors)
  else
    dispatchError(null)

}, [form?.errors]);

	return <Container p={"md"} sx={{position:'relative'}} size="md" >
          <Stack spacing={16}  >
            <Paper shadow="xs" p="sm">
              <Stack spacing={8}  >
                <TextInput {...form.getInputProps('name')} withAsterisk size="md" label="Name" required placeholder="Name your event" />
                <TextInput {...form.getInputProps('abbr')} size="md" label="Abbreviation" placeholder="Abbreviation" />
                
                <Textarea {...form.getInputProps('description')} label="Description" withAsterisk required size="md" rows={30} placeholder="Description" />
              </Stack>              
            </Paper>
            <Grid grow gutter="xl">
              <Grid.Col md={6} style={{ minHeight: 80 }}>
                <Stack spacing={8}  >            
                  <Group position="apart" sx={{position:'relative'}}>
                    <Text size="sm">Color (Optional)</Text>
                    <HoverCard width={280} shadow="md" position="left-end">
                      <HoverCard.Target>
                        <UnstyledButton className="opacity-[.75]"><BsLightbulb key="event-arrival-hint"/></UnstyledButton>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Text size="sm">
                          Set the event colors <br/>
                          <strong className="text-orange-500">Primary</strong>: This is the main event color.<br/>
                          <strong className="text-purple-500">Secondary</strong>: This is the accent color of teh event
                        </Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Group>
                  <Paper shadow="xs" p="sm">
                    <div className="mb-4">
                      <ColorInput size="md"
                        swatches={swatches}
                        {...form.getInputProps("primary_color")}
                        withPicker={false}
                        format="hsl"
                        label="Primary"
                      />
                    </div>

                    <div className="mb-4">
                      <ColorInput size="md"
                        swatches={swatches}
                        {...form.getInputProps("accent_color")}
                        withPicker={false}
                        format="hsl"
                        label="Secondary"
                        index={2}
                      />
                    </div>
                  </Paper>

                   <UploadManager
                      label="Cover Image (Optional)"
                      max={1}
                      onFinish={(value) => form?.setFieldValue("cover_image", value)}
                    />

                </Stack>
                
            </Grid.Col>
            <Grid.Col md={6} style={{ minHeight: 120 }}>
              <Stack spacing={8} >          
                  <Group position="apart" sx={{position:'relative'}}>
                    <Text size="sm">Arrival </Text>
                    <HoverCard width={280} shadow="md" position="right-end">
                      <HoverCard.Target>
                        <UnstyledButton className="opacity-[.75]"><BsLightbulb key="event-arrival-hint"/></UnstyledButton>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Text size="sm">
                          Enter data for when the event will commence
                        </Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Group>
                  <Paper shadow="xs" p="sm">
                    <Group position="apart">
                      <TimeInput size="md" className="grow" label="Time" clearable/>
                      <DatePicker size="md" className="grow" placeholder="Event start date (optional)" label="Date" clearable/>
                    </Group>
                  </Paper>

                  <Group position="apart" sx={{position:'relative'}}>
                    <Text size="sm">Departure (Optional)</Text>
                    <HoverCard width={280} shadow="md" position="right-end">
                      <HoverCard.Target>
                        <UnstyledButton className="opacity-[.75]"><BsLightbulb key="event-departure-hint"/></UnstyledButton>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Text size="sm">
                          Enter data for when the event will end
                        </Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Group>
                  <Paper shadow="xs" p="sm">
                    <Group position="apart">
                      <TimeInput size="md" className="grow" label="Time" clearable/>
                      <DatePicker size="md" className="grow" placeholder="Enter end date (optional)" label="Date" clearable/>
                    </Group>
                  </Paper>

                
                <Group position="apart" sx={{position:'relative'}}>
                  <Text>Search Keywords (tags)</Text>
                  <HoverCard width={280} shadow="md" position="right-end">
                    <HoverCard.Target>
                      <UnstyledButton className="opacity-[.75]"><BsLightbulb/></UnstyledButton>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size="sm">
                        Get your event seen and easily searchable using common search keywords or tags
                      </Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Group>
                <MultiSelect
                  data={[]}
                  placeholder="Select keywords"
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    const list = form?.values?.keywords;
                    list.push(item)
                    form?.setFieldValue('keywords',list);
                    return item;
                  }}
                />
              </Stack>                        
            </Grid.Col>
          </Grid>
				
        {/*<Select label="Event" placeholder="Select an event" />
        <Select
          label="Location"
          placeholder="Where is this event likely to happen?"
        />*/}
        
        {/* <div className="seo">
          <p>SEO Tags</p>
          <div>
            <Tag text="SEO Tag" />
            <Tag text="Software Engineering" />
            <Tag text="Technology" />
          </div>
        </div>*/}
          </Stack>

        </Container>
}

export default connectToStore(EventDetailForm);