import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import session from "./session";
import user from "./user";
import setting from "./setting";
import wallet from "./wallet";
import draft from "./draft";

const persistConfig = {
  key: "nikcano-store",
  // version: 1,
  storage,
  // whitelist: ["user", "setting", "media", "receipts"],
  blacklist: [],
};

/**
 * @typedef {object} reducers
 * @property {Session} session
 */
const allReducers = {
  session,
  user,
  wallet,
  setting,
  draft
};

const appReducer = combineReducers(allReducers);

const rootReducer = (state, action) => {
  if (action.type === "session/$CLEAR") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("nikcano-store");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        warnAfter: 150,
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "Profile/wallet",
        ],
      },
      immutableCheck: {
        warnAfter: 150,
        // ignoredPaths: [
        //   FLUSH,
        //   REHYDRATE,
        //   PAUSE,
        //   PERSIST,
        //   PURGE,
        //   REGISTER,
        //   "Profile",
        //   "Session",
        //   "Chart",
        // ],
      },
    }) /* .concat(logger), */,
});
// The store now has redux-thunk added and the Redux DevTools Extension is turned on

export const persistor = persistStore(store);

export default appReducer;
