// user.api.js

import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "analytics");
    this._name = "Analytics";
    return this;
  }
  /**
   * @function register - Register a new user
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.email
   * @param {string} args.data.username
   * @param {string} args.data.password
   * @param {string} args.data.re_password
   * @param {string} [args.data.referrer]
   * */
  async dashboard() {
    const res = await this.axios(
      {
        url: "/dashboard/",
        method: "get",
      },
      true
    );
    return res.data;
  }
}
