import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {import("../../types").Reducer.Setting}
 */
const initialState = {
  theme: null,
  is_2fa_enabled: false,
  is_live: false,
  is_push_notification_enabled: false,
  is_network_caching_enabled: true,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    $SET: (state, action) => {
      return { state,...action.payload };
    },
    $CLEAR: (state, action) => {
      return initialState;
    },
  },
});

export const actions = settingSlice.actions;
export default settingSlice.reducer;
