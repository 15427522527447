import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { connectToStore } from "../../lib";

function EventPage() {
  return <Outlet />
}

export default connectToStore(EventPage);


