import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../hook";
import { EventIcon2, InfoIcon, OrganizerIcon, PhoneIcon } from "../Svgs";
import avatar from "../../images/avatar.png";
import Card from "../Card";
import { connectToStore } from "../../lib";
import { Button, Textarea, TextInput, Select } from "@mantine/core";
import toast from "react-hot-toast";
import { useForm, yupResolver } from "@mantine/form";

const validationSchema = (userState) =>
  Yup.object({
    first_name: Yup.string().required("First name field is required"),
    last_name: Yup.string().required("Last name field is required"),
  });
// .test({
//       name: "field_changed",
//       test: ({first_name, last_name}) => {
//         return !(userState?.last_name == last_name && userState?.first_name == first_name ) ;
//       },
//       message: "Field has not changed",
//       exclusive: true,
//     });

const Personal = (props) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const form = useForm({
    initialValues: {
      first_name: props?.UserState?.first_name,
      last_name: props?.UserState?.last_name,
      about: props?.UserState?.about,
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(validationSchema(props?.UserState)),
  });

  async function onUpdate(values, { setSubmitting }) {
    try {
      isMounted?.current && setLoading(true);
      const update = await api.User?.updateMe({ data: values });
      props?.UserDispatch.$SET(update);
      toast.success("Profile updated successfully!", {
        id: "update-profile-success",
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      });
    } catch (err) {
      console.error("ERR::updateUserProfile", err);
    } finally {
      isMounted?.current && setLoading(false);
    }
  }

  return (
    <PersonalStyled>
      <div className="col-1">
        <Card>
          <div className="user">
            <figure className="p-2 mb-[8px]">
              <img src={avatar} alt="" srcSet="" />
            </figure>
            <p className="name">{props?.UserState?.full_name}</p>
            <span className="extra">{props?.SessionState?.email}</span>
            <span className="extra">@{props?.UserState?.username}</span>
          </div>
          {false && (
            <div className="details">
              <div>
                <OrganizerIcon />
                <p>Organizer</p>
              </div>
              <div>
                <InfoIcon />
                <p>
                  Direct repair of aneurysm, pseudoaneurysm, or excision
                  (partial or total) and graft insertion with ...
                </p>
              </div>
              <div>
                <EventIcon2 height="12" width="13" />
                <p>
                  Created <b>4</b> events so far
                </p>
              </div>
              <div>
                <PhoneIcon />
                <p>+234 821 3452 239</p>
              </div>
            </div>
          )}
        </Card>
      </div>
      <div className="col-2">
        <Card title={"Update personal information"}>
          <form onSubmit={form?.onSubmit(onUpdate)}>
            <div className="mb-4">
              <TextInput
                label={"First name"}
                placeholder="Your first name"
                {...form.getInputProps("first_name")}
              />
              <TextInput
                {...form.getInputProps("last_name")}
                placeholder="Your last name"
                label={"Last name"}
              />
            </div>
            <Button
              className="w-full"
              size="md"
              loading={loading}
              type="submit"
              disabled={!form?.isValid}
            >
              Save Changes
            </Button>
          </form>
        </Card>
      </div>
    </PersonalStyled>
  );
};

export default connectToStore(Personal);

const PersonalStyled = styled.section`
  display: flex;
  gap: 0 30px;
  .user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    img {
      width: 120px;
      height: 120px;
    }

    .name {
      margin: 15px 0 8px 0;
      font-weight: 700;
      font-size: 21px;
      line-height: 24px;
    }

    .extra {
      font-weight: 400;
      font-size: 14px;
      opacity: 0.5;
    }
  }

  .details {
    // margin-top: 31px;
    padding: 20px;
    > div {
      display: flex;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #1d1d63;
      margin-bottom: 9px;
    }

    svg {
      margin-right: 8px;
      width: 13px;
      height: 13px;
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
