import styled from "styled-components";
import work1 from "../../images/work1.png";
import work2 from "../../images/work2.png";
import work3 from "../../images/work3.png";

const WorkBox = () => {
  return (
    <WorkBoxStyled>
      <div>
        <img src={work1} alt="" srcSet="" />
        <p className="title">Sign Up</p>
        <span className="subtitle">Create an account</span>
      </div>
      <div>
        <img src={work2} alt="" srcSet="" />
        <p className="title">Create Contest</p>
        <span className="subtitle">Login and create contest</span>
      </div>
      <div>
        <img src={work3} alt="" srcSet="" />
        <p className="title">Manage Contest</p>
        <span className="subtitle">
          Manage all your contest. <br /> Share and have fun
        </span>
      </div>
    </WorkBoxStyled>
  );
};

export default WorkBox;

const WorkBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 133px;
  }
  div:nth-child(3) {
    margin-right: 0px;
  }

  img {
    width: 45px;
    height: 45px;
    margin-bottom: 0;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.04em;
    margin: 15px 0;
  }

  .subtitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    opacity: 0.5;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    div {
      margin-bottom: 28px;
      margin-right: 0;
    }
  }
`;
