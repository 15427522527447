import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { IoCopyOutline } from "react-icons/io5";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { SearchIcon } from "../Svgs";
import { ReferralList } from ".";
import Card from "../Card";
import ListCard from "../ListCard";
import { connectToStore } from "../../lib";
import { useApi } from "../../hook";
import { Button } from "@mantine/core";
import toast from "react-hot-toast";
import Clipboard from "clipboard";

const Referrals = (props) => {
  const api = useApi();
  const [list, setList] = useState([]);
  const [status, setStatus] = useState('Copy');
  const isMounted = useRef(true);

  function onCopy() {
    isMounted.current && setStatus("Copied");
    setTimeout(() => {
      isMounted.current && setStatus("Copy");
    }, 1000);
  } 

  function onCopyError() {
    isMounted.current && setStatus(<BsFillExclamationTriangleFill/>);
    setTimeout(() => {
      isMounted.current && setStatus("Copy");
    }, 1000);
  }
  async function fetchReferralList() {
    try {
      // const list = await ;
    } catch (error) {
      console.error(error, "ERR::Referrals.fetchReferralList");
    }
  }

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;
    const clipboard = new Clipboard(".copier");
    clipboard.on('success', onCopy)
    clipboard.on('error', (e) => {
     onCopyError(e); console.error(e)
    })
    fetchReferralList();

    return () => {
      isMounted.current = false;
      clipboard.destroy();
    };
  }, []);

  return (
    <ReferralsStyled>
      <div className="flex-none">
        <Card title={"Invite Friends"}>
          <p className="subtitle">
            Earn more when you share and invite friends using your referral
            code.
          </p>
          <div className="referral-code-box">
            <p className="code">{props?.UserState?.referral_code}</p>
            <Button
              className="select-none copier" 
              data-clipboard-text={props?.UserState?.referral_code}
              variant="subtle"
              leftIcon={<IoCopyOutline />}
              size="sm"
            >
              {status}
            </Button>
          </div>
        </Card>
      </div>
      <div className="grow">
        <ListCard searchable list={list}>
          {({ list }) => list?.map((item) => <ReferralList />)}
        </ListCard>
      </div>
    </ReferralsStyled>
  );
};

export default connectToStore(Referrals);

const ReferralsStyled = styled.section`
  display: flex;
  gap: 0 30px;
  .referral-code-box {
    display: flex;
    padding: 8px;
    user-select: none;
    background-color: #fafafa;
    background: ${({ theme }) =>
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.background[1]};
    border-radius: 4px;
    .code {
      flex: 1;
      line-height: 16px;
      font-size: 14px;
      padding: 8px;
      align-self: center;
      opacity: 0.5;
      font-weight: 600;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 13.0503px;
    line-height: 15px;
    max-width: 300px;
    margin-bottom: 15px;
    color: rgba(140, 140, 140, 0.7);
    user-select: none;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    .col-2 {
      margin: 0;
    }
  }
`;
