// ListTransaction.jsx
import styled from "styled-components";
import { useState, useEffect } from "react";
import { connectToStore } from "../../lib";
import ListCard from "../ListCard";

function ListTransaction() {
  const [activeTab, setActiveTab] = useState("credit");
  const [list, setList] = useState({
    credit: {
      label: "Credit",
      data: [],
    },
    debit: {
      label: "Debit",
      data: [],
    },
  });

  useEffect(() => {
    /*Fetch the wallet transaction history*/
  }, [activeTab]);

  return (
    <>
      {/*Tab*/}
      <section>
        <ul className="flex gap-[12px] min-h-[50px] wallet-nav">
          {Object.entries(list).map(([k, v], index) => (
            <li
              onClick={() => setActiveTab(k)}
              key={index}
              className={` ${
                activeTab == k
                  ? "border-b-2 border-b-orange-400 font-bold text-orange-400"
                  : ""
              } p-[8px] hover:text-orange-400 select-none cursor-pointer capitalize`}
            >
              {k}
            </li>
          ))}
        </ul>
      </section>
      {/*Tab content*/}
      <>
        <ListCard list={list[activeTab]?.data}>{({ list }) => <></>}</ListCard>
      </>
    </>
  );
}

export default connectToStore(ListTransaction);
