import { createContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faker } from "@faker-js/faker";
import { actions as userActions } from "../store/reducer/user";
import { actions as sessionActions } from "../store/reducer/session";
import useApi from "../hook/useApi";

const { $CLEAR: clearSession, $SET: setSession } = sessionActions;
const { $SET: setUser } = userActions;
/**
 * @type {import("../types").Reducer.Session} defaultState
 */

const initialState = {
  token: "",
  refresh: "",
  access: "",
  email: "",
};

export const AuthContext = createContext({
  /**
   * @description Returns current user;s information
   * @type {object}
   * @returns {object | null}
   */
  currentUser: null,

  /**
   * @description Indicates user login status
   * @type {boolean}
   */
  isLoggedIn: false,

  /**
   * @description Create new user session
   * @param {object} profile user profile object
   * @returns {void}
   */
  startSession: (profile) => null,
  /**
   * @function fetchSession - Fetches user session information
   * @returns {void}
   */
  fetchSession: () => null,

  /**
   * @type {AuthContextType}
   */
  session: {},
});

export const AuthProvider = ({ children }) => {
  const state = useSelector((state) => state);
  const { user: currentUser, session } = state;
  const dispatch = useDispatch();
  const api = useApi();

  // console.log({ state });
  useEffect(() => {
    // console.debug("State changed", state);
  }, [state]);

  async function fetchUserProfile() {
    try {
      return await api.User?.me();
    } catch (error) {
      console.error("ERR::authContext::fetchUserProfile", error);
    }
  }

  async function startSession() {
    try {
      const me = await fetchUserProfile();
      if (me) {
        dispatch(setUser(me));
      }
    } catch (error) {
      console.error("", error);
    }
  }

  /**
   * @type {AuthContextType}
   */
  const authValue = {
    startSession,
    currentUser,
    session,
    fetchUserProfile,
    isLoggedIn: Boolean(state.session?.isLoggedIn),
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};
