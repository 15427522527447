// WalletSummary.jsx

import { connectToStore } from "../../lib";
import Card from "../Card";
import { Button, Text, Stack, Group, Title, Center } from "@mantine/core";

function WalletSummary() {
  return (
    <>
      <Card shadow="sm">
        <Center>
          <Stack p={20}>
            <span className="text-center">Current Balance</span>
            <Title center>16,560.00</Title>
          </Stack>
        </Center>
        <Stack>
          <Group grow>
            <Button>Deposit</Button>
            <Button color="red">Withdraw</Button>
          </Group>
        </Stack>
      </Card>
    </>
  );
}

export default connectToStore(WalletSummary);
