import styled from "styled-components";
import logo from "../images/icons/logo.png";
import { useLocation, Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { CancelIcon } from "./Svgs";
import { useState } from "react";
import { urls } from "../urls";
import { routeMap } from "../route-map";

const Navbar = () => {
  const { pathname } = useLocation();
  const [hideNavs, setHideNavs] = useState(true);
  const checkPathname = (path) => {
    if (pathname === path) return "nav-link active";
    return "nav-link";
  };

  // const navLinks = document.querySelectorAll('.nav-link');
  // navLinks.forEach(link => {
  //     link.addEventListener('click', () => hideNavLinks())
  // })

  return (
    <HeaderStyled>
      <header>
        <div>
          <Link to="/">
            <img src={logo} alt="" srcSet="" />
          </Link>
          {!hideNavs && (
            <div className="mobile-nav">
              <ul className="nav-links">
                <li className={checkPathname("/blog/")}>Blog</li>
                <Link to={routeMap.about}>
                  <li className={checkPathname("/about/")}>About </li>
                </Link>
                <li className="nav-link">Contact Us</li>
                <Link to="/events">
                  <li className={checkPathname("/events")}>Events</li>
                </Link>
                <Link to={routeMap?.auth?.login}>
                  <li
                    id="login"
                    className={checkPathname(routeMap?.auth?.login)}
                  >
                    Login
                  </li>
                </Link>
                <Link to={routeMap?.auth?.register}>
                  <li className={checkPathname(routeMap?.auth?.register)}>
                    <button className="register">Register</button>
                  </li>
                </Link>
              </ul>
            </div>
          )}
        </div>
        <nav>
          <ul>
            <Link to={routeMap?.blog}>
              <li className={checkPathname(routeMap?.blog)}>Blog</li>
            </Link>
            <Link to={routeMap?.about}>
              <li className={checkPathname(routeMap?.about)}>About </li>
            </Link>
            <Link to={routeMap?.contact}>
              <li className={checkPathname(routeMap?.contact)}>Contact Us</li>
            </Link>
            <Link to={routeMap?.event}>
              <li className={checkPathname(routeMap?.event)}>Events</li>
            </Link>
          </ul>
          <ul>
            <Link to={routeMap?.auth?.login}>
              <li className="login">Login</li>
            </Link>
            <Link to={routeMap?.auth?.register}>
              <button className="register">Register</button>
            </Link>
          </ul>
        </nav>
        <div id="menu">
          {hideNavs ? (
            <span onClick={() => setHideNavs(false)}>
              <FaBars />
            </span>
          ) : (
            <span onClick={() => setHideNavs(true)}>
              <CancelIcon />
            </span>
          )}
        </div>
      </header>
    </HeaderStyled>
  );
};

export default Navbar;

const HeaderStyled = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    box-shadow: 0px 4px 93px rgba(0, 0, 0, 0.11);
  }
  header img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  header ul {
    display: flex;
    align-items: center;
  }

  header nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  header ul {
    padding: 0;
  }

  header .nav-link {
    font-size: 16px;
    line-height: 18.8px;
    font-weight: 700;
    margin-left: 15px;
    cursor: pointer;
  }

  header .login {
    margin-left: 55px;
    margin-right: 30px;
    font-weight: 700;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    line-height: 19px;
    color: #1d1d63;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }

  header .register {
    background: #f78f08;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 4px 20px rgba(241, 155, 0, 0.2);
    border-radius: 4px;
    color: white;
    padding: 10px 15px;
  }
  .active {
    color: #f78f08;
  }
  #menu {
    display: none;
  }
  @media screen and (max-width: 480px) {
    header {
      nav {
        display: none;
      }
    }
    .nav-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .nav-link {
        margin-left: 0;
        margin-top: 15px;
      }
      #login {
        color: #f78f08;
      }
    }
    #menu {
      display: block;
      cursor: pointer;
      margin-top: 12px;
    }
  }
`;
