// DeleteAccount.jsx
import {useRef, useState, useEffect} from 'react';
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Button, TextInput, Stack } from "@mantine/core";
import { useForm, yupResolver} from "@mantine/form";
import Card from "../Card";
import { useApi } from "../../hook";
import { connectToStore, parseErrorMessage } from "../../lib";

function DeleteAccount({ UserState, DispatchSession }) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .oneOf([UserState?.username, null], "Must match your username")
      .required("Username field is required"),
  });

  const form = useForm({
    initialValues: {
      username: "",
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(validationSchema),
  });

  useEffect(() => {
      if(!isMounted.current)
        isMounted.current = true;

      return () => {
        isMounted.current = false
      }
    }, []);

  async function deleteMe(data) {
    try {
      isMounted.current && setLoading(true);
      await api.User?.deleteMe();
      DispatchSession?.$CLEAR();

      toast.success("Your account has been deleted permanently", {
        id: "delete-me-success",
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      });
    } catch (error) {
      parseErrorMessage(error);
    } finally {
      isMounted.current && setLoading(false);
    }
  }
  return (
    <>
      <Card expand={false} title={"Delete Account"}>
        <form onSubmit={form.onSubmit(deleteMe)}>
          <Stack>
            <TextInput size="md"
              {...form.getInputProps("username")}
              autocomplete={"off"}
              autocapitalize={"off"}
              label={`Enter your username to confirm`}
              placeholder={UserState?.username || "@username"}
            />
            <Button
              disabled={!form?.isValid()}
              type="delete"
              loading={loading}
              className="w-full"
              size="md"
              color={"red"}
            >
              Delete Account
            </Button>
          </Stack>
        </form>
      </Card>
    </>
  );
}

export default connectToStore(DeleteAccount);
