import {Link} from "react-router-dom"
import styled from "styled-components";
import {  Group, Stack, UnstyledButton } from "@mantine/core";
import InteractiveContent from "../components/Home/InteractiveContent";
import Manage from "../components/Home/Manage";
import Works from "../components/Home/Works";
import Footer from "../components/Footer.Default";
import { PlayIcon } from "../components/Svgs";
import { routeMap } from "../route-map";
import Logo from "../components/Logo";
import {connectToStore} from "../lib";
import { BsPlayCircleFill } from "react-icons/bs";

const Landing = ({SessionState}) => (
  <PageContainer>
    <InnerContainer   >
      <Group  spacing="lg" id="mast" >
        <Stack className="lhs" sx={{marginBottom: 20}}>
          <div className="mb-[20px]">
            <h2>Host a competition</h2>
            <p className="subtitle">Reduce the hassle</p>
            <span>
              Host, manage users registration, tickets and create interactive
              contents for your event, all in one place
            </span>
          </div>
          <UnstyledButton component={Link} to={routeMap?.auth?.register} className="action">Start your free trial</UnstyledButton>
        </Stack>

        <Stack className="rhs">
          <div id="mast-video">
            <button className="play-btn">
              <BsPlayCircleFill />
            </button>
          </div>
        </Stack>
      </Group>
    </InnerContainer>
    <Manage />
    <InteractiveContent />
    <Works />
    <Footer />
  </PageContainer>
);
export default connectToStore(Landing);

const InnerContainer = styled.div`

margin: auto;
width: 100%;
padding: 60px 20px;
max-width: calc(100vw - 250px);
  @media screen and (max-width: 480px) {
    max-width: 100%;
    padding: 10px
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  #mast {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-grow: 1;
    column-gap: 30px;
    .lhs {
      flex-grow: 1;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 350px;
      h2 {
        font-weight: 700;
        font-size: 54px;
        line-height: 48px;
        letter-spacing: -0.04em;
        color: ${({ theme }) =>
          theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.colors.dark[9]};
      }
      .subtitle {
        font-weight: 400;
        font-size: 39px;
        margin-top: 9px;
        margin-bottom: 18px;
        line-height: 47px;
        letter-spacing: -0.04em;
        color: #f78f08;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        // color: #1d1d63;
        opacity: 0.5;
      }
      .action {
        width: 300px;
        height: 66.79px;
        background: linear-gradient(0deg, #ffd200 0%, #fb8100 100%);
        border-radius: 4px;
        justify-content: center;
        display: flex;
        align-items: center;
        color: #000;
        font-weight: 700;
        font-size: 18px;
        appearance: none;
        font-family: inherit;
        border: 2px solid transparent;
      }
    }

    .rhs {
      flex-grow: 1;      
      display: flex;
      align-items: center;

      #mast-video {
        width: 100%;
        height: 100%;
        min-width: 300px;
        min-height: 350px;
        max-height: 400px;
        background: #050512;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        .play-btn {
          appearance: none;
          border: none;
          border-radius: 45px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          svg {
            fill: #050512;
            color: #050512;
            font-size: 45px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    #mast {
      width: 100%;
      max-width: 100%;
    }
    header {
      padding-left: 20px;
      padding-right: 20px;
    }
    #mast {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      .lhs {
        width: 100%;
        margin-bottom: 20px;

        .action {
          width: 100%;
        }
      }
      .rhs {
        width: 100%;
        height: 300px;
        background: purple;
      }
    }
  }
`;
