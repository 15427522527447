// ListForm.jsx

import { connectToStore } from "../../lib";
import ListCard from "../ListCard";

function ListForm() {
  return <ListCard>{({ list }) => <></>}</ListCard>;
}

export default connectToStore(ListForm);
