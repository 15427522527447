import { Outlet, Link } from "react-router-dom";
import { useWindowScroll } from '@mantine/hooks';
import { Affix, Button, Text, Transition, Group } from '@mantine/core';
import {BsFillCaretUpFill} from "react-icons/bs";
import Logo from "../components/Logo"
import {routeMap} from "../route-map"
import styled from "styled-components"
import UserCard from "../components/UserCard";
import {connectToStore} from "../lib";

function GuestLayout({SessionState}) {
   const [scroll, scrollTo] = useWindowScroll();
  return (
    <>
      {/* <GlobalNav /> */}
      <main>
        <StyledNav>
          <Logo base="/" />
          <Group >
            {SessionState?.isLoggedIn 
              ?  <UserCard linkable>
                  <div>
                    <Text>You're signed in</Text>
                    <Text size="xs">{SessionState?.email}</Text>
                  </div>
                </UserCard> : <>
              <Link to={routeMap?.auth?.login}>
                <Button size="md" variant="subtle">
                  Login
                </Button>
              </Link>
              <Link to={routeMap?.auth?.register}>
                <Button size="md">Register</Button>
              </Link>
            </>}
          </Group>
        </StyledNav>
        <Outlet />
        <Affix position={{ bottom: 20, right: 20 }}>
          <Transition transition="slide-up" mounted={scroll.y > 0}>
            {(transitionStyles) => (
              <Button
                leftIcon={<BsFillCaretUpFill size={16} />}
                style={transitionStyles}
                onClick={() => scrollTo({ y: 0 })}
              >
                Scroll to top
              </Button>
            )}
          </Transition>
        </Affix>
      </main>
    </>
  );
}

export default connectToStore(GuestLayout);

const StyledNav = styled.nav`
   padding: 20px;
   max-width: calc(100vw - 250px);
   margin: auto;
    display: flex;
    justify-content: space-between;
    .nav-right {
      display: flex;
      justify-content: space-between;      
    }
    @media screen and (max-width: 768px) {
      max-width: 100%
    }
`;