// Ticket.Create.tsx
import { useEffect } from "react";
import { connectToStore } from "../../lib";
import { Add } from "../../components/Ticket";

function TicketCreationPage() {
  return (
    <>
      <div>
        <h2>Create Ticket</h2>
      </div>
      <Add />
    </>
  );
}

export default connectToStore(TicketCreationPage);
