// Contestant.List.tsx
import { connectToStore } from "../../lib";
import { Button } from "@mantine/core";
import { List } from "../../components/Contestant";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { routeMap } from "../../route-map";

function ContestantListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="flex justify-between items-baseline">
        <h2>Contestant list</h2>
        <Button
          onClick={() =>
            navigate(routeMap?.account?.contestant_add, {
              state: { from: location?.pathname },
            })
          }
        >
          Create new
        </Button>
      </div>
      <List />
    </>
  );
}
export default connectToStore(ContestantListPage);
