// CreateEvent.jsx
import React from 'react';
import styled from "styled-components";
import {BsFillExclamationTriangleFill} from "react-icons/bs"
import { Tabs, Button,Indicator, Text,Code, Switch, Group, Divider, Alert } from '@mantine/core';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {DetailForm, AddActivity} from "./index"
import { connectToStore } from "../../lib";
import {nanoid} from "nanoid";

const empty = () => <></>
const tabs = [
  {
    icon: <></>,
    label: "Details",
    id: "event-detail-form",
    view: DetailForm,
  },{
    icon: <></>,
    label: "Activities",
    id: "event-activity-form",
    view: AddActivity
  },{
    icon: <></>,
    label: "Ticket",
    id: "event-ticket-form",
    view: empty,
  },{
    icon: empty,
    label: "Form",
    id: "event-form-form",
    view: empty,
  },{
    icon: empty,
    label: "Judges",
    id: "event-judge-form",
    view: empty,
  },{
    icon: empty,
    label: "Contestants",
    id: "event-participant-form",
    view: empty,
  },{
    icon: empty,
    label: "Gallery",
    id: "event-gallery-form",
    view: empty,
  }
]

const initialState = {
  name: "",
  description: "",
  cover_image: "",
  published_at: "",
  schedule: null,
  seo: {
    title: "",
    description: "",
    keywords: []
  },
  colors: {
    primary: null,
    secondary: null
  },
  uploads: [],
  tickets: [],
  activities: [],
  tags: [],
  judges: [],
  participants: [],
  forms: [],
  elimination_strategy: null
}

const reducer = (state, {type, value}) => {
  switch(type){
    case 'default': {
      const validKeys = Object.keys(initialState);
      if(!validKeys?.includes(type))
        throw new Error(`Not a valid key. Valid keys are ${validKeys?.join(",")}`);
      return {...state, [type]: value }
    }
  }
};

function AddEvent({DraftDispatch, DraftState}) {
  const navigate = useNavigate();
  const params = useParams();
  const {pathname, search} = useLocation();
  const query = new URLSearchParams(search || `?tab=${tabs[0]?.id}`);
  const isMounted = React.useRef(true);
  const currentTimestamp = React.useRef(new Date().getTime());
  const [eventState, eventDispatch] = React.useReducer(reducer, {
    [currentTimestamp?.current]: initialState
  });
  const [draft, setDraft] = React.useState(true);
  const [errors, setErrors] = React.useState({})

  function handleSync(data) {
    const temp = eventState
  }

  React.useEffect(() => {
    if(!isMounted.current)
      isMounted.current = true;

    return () => {
      isMounted.current = false
    }
  }, [])

  async function onSubmit(e) {
    e.preventDefault();
    try{}catch(error){}finally{}
  }

  return (
    <div className="flex flex-col">
      {draft ? <StyledAlertContainer className="sticky top-0 z-[2]">
        <Alert icon={<BsFillExclamationTriangleFill size={16}/>} title="Notice"  color="teal">
          <Text >Event data created here are only stored locally in your computer's browser store. To persist your data in our remote database, you will have to publish your event</Text>
        </Alert>
      </StyledAlertContainer>: null}
      <form onSubmit={onSubmit} className="grow z-[1] min-h-[75vh]">
        <Tabs keepMounted={true} defaultValue={query?.get('tab')}
          onTabChange={(value) => navigate(`?tab=${value}`)}>
          <TabListStyled  position="center" sx={{}}>
          {
            tabs?.map(({label, icon, id}) => {
              return <Tabs.Tab sx={{padding: 20, borderRadius: 0,  '&:hover':{
              backgroundColor: 'transparent'
            }}} key={id} value={id} icon={icon} >
              {errors[id] ? <Indicator color="red" offset={-2} inline>
                            <Text color="red">{label}</Text>
                            </Indicator> : <Text>{label}</Text>}
            </Tabs.Tab>
            })
          }
          <div className="self-center ml-auto pr-4" >
            <Button type="submit" disabled={draft}>Publish</Button>              
          </div>
          </TabListStyled>

          <div className="">
          <Group className="p-4 justify-end ">
        <Switch checked={draft}
        onChange={() => setDraft(!draft)}
        labelPosition="left"
        description={"Enable to work as a draft"} label="Work in progress" />
      </Group>
      <Divider mx={"sm"} label="Disable to publish" labelPosition="right"/>
      
            {tabs?.map(({ id, view }) => (
              <Tabs.Panel key={`view-${id}`} 
                value={id} >
                {React.createElement(view, {
                  dispatchChange: eventDispatch,
                  dispatchError: ((id) => (error) => {
                    setErrors(prev => ({
                      ...prev, [id]: error
                    }))
                  })(id)
                }, null)}
              </Tabs.Panel>
              )
            )
          }
          </div>
        </Tabs>
      </form>


      

      {/*<Code>{JSON.stringify(DraftState)}</Code>
      <Code>{JSON.stringify(eventState)}</Code>*/}
    </div>
  );
}

export default connectToStore(AddEvent);

const StyledAlertContainer = styled("div")`
  background: ${({ theme }) =>
    theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.colors.background[1]};
`;

const TabListStyled = styled(Tabs.List)`
  background: ${({ theme }) =>
    theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.colors.background[1]};
  
`;