import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {import("../../types").Reducer.User}
 */
const initialState = {
  connected_accounts: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    $SET: (state, action) => {
      return {state, ...action.payload};
    },
  },
});

export const actions = userSlice.actions;
export default userSlice.reducer;
