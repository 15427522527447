// Logo.jsx
import logo from "../images/icons/logo.png";
import { routeMap } from "../route-map";
import { Link } from "react-router-dom";
import {connectToStore} from "../lib"

function Logo({ style, SessionState }) {
  return (
    <>
      <Link style={{ display: "flex", alignItems: "center" }} to={SessionState?.isLoggedIn ? routeMap?.account?.index : routeMap?.landing}>
        <img src={logo} alt="" style={{ ...style }} srcSet="" />
        {/*<p style={{marginLeft: 8}}>Nikcano</p>*/}
      </Link>
    </>
  );
}

export default connectToStore(Logo);
