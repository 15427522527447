import * as React from "react";
import { useRoutes } from "react-router-dom";

import AccountLayout from "./layout/account.layout";
import AuthLayout from "./layout/auth.layout";
import GuestLayout from "./layout/guest.layout";

import AccountOverview from "./pages/account/Overview";

import AccountProfile from "./pages/account/Profile";

import AccountEvent from "./pages/account/Event";
import AccountEventAdd from "./pages/account/Event.Add";
import AccountEventList from "./pages/account/Event.List";

import AccountTicketList from "./pages/account/Ticket.List";
import AccountTicketAdd from "./pages/account/Ticket.Add";
import AccountTicket from "./pages/account/Ticket";

import AccountContestant from "./pages/account/Contestant";
import AccountContestantList from "./pages/account/Contestant.List";
import AccountContestantAdd from "./pages/account/Contestant.Add";

import AccountActivityAdd from "./pages/account/Activity.Add";
import AccountActivityList from "./pages/account/Activity.List";
import AccountActivity from "./pages/account/Activity";

import AccountJudge from "./pages/account/Judge";
import AccountJudgeAdd from "./pages/account/Judge.Add";
import AccountJudgeList from "./pages/account/Judge.List";

import AccountForm from "./pages/account/Form";
import AccountFormAdd from "./pages/account/Form.Add";
import AccountFormList from "./pages/account/Form.List";

import AccountWallet from "./pages/account/Wallet";

import AccountSetting from "./pages/account/Setting";

import Contact from "./pages/Contact";
import Error404 from "./pages/error/Error404";
import Landing from "./pages/Landing";
import SignIn from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";

const About = React.lazy(() => import("./pages/About"));
// route-map.js
const stripSlashesRegExp = new RegExp("(?<!(http(s):))/{2,}", "g");

/**
 * @function buildRoutePath - Builds the route path, including query parameters
 * @params {object} config - route configuration object
 * @params {object} config.query - Query parameters object
 * @params {object} config.basePath - Route base path
 **/
export const buildRoutePath =
  (path = "") =>
  (config = {}) => {
    const { query, basePath = "" } = config;
    try {
      const searchParams = new URLSearchParams(query);
      return `${basePath ? basePath : ""}/${path}${
        query ? "/?" + searchParams?.toString() : ""
      }`.replace(stripSlashesRegExp, "/");
    } catch (error) {
      console.error(`Error creating path ${path}`);
    }
  };

class shapeRoute {
  constructor(basePath = "", index = "") {
    // if(!basePath)
    // 	throw new Error(`basePath cannot be an empty string`);
    this.basePath = basePath;
    this.index = index;
  }

  register(paths) {
    // if (this.index)
    this.paths = {
      index: buildRoutePath(this.index)({ basePath: this.basePath }),
    };
    Object.entries(paths).forEach(([path, pathValue]) => {
      if (typeof pathValue === "function") {
        this.paths = {
          ...this.paths,
          [path]: pathValue({ basePath: this.basePath }),
        };
      } else if (typeof pathValue === "string") {
        this.paths = {
          ...this.paths,
          [path]: String(this.basePath + "/" + pathValue).replace(
            stripSlashesRegExp,
            "/"
          ),
        };
      }
    });
    return this.paths;
  }
}

const auth = new shapeRoute("/auth");
const error = new shapeRoute("/*");
const account = new shapeRoute("/account");

const authRouteMap = {
  login: buildRoutePath("login"),
  register: buildRoutePath("register"),
  forgotPassword: buildRoutePath("forgot-password"),
};

const accountRouteMap = {
  overview: buildRoutePath("overview"),
  profile: buildRoutePath("profile"),
  setting: buildRoutePath("setting"),
  // Event
  event: buildRoutePath("event"),
  event_add: buildRoutePath("event/add"),
  event_create: buildRoutePath("event/add"),
  event_list: buildRoutePath("event/list"),
  // Ticket
  ticket: buildRoutePath("ticket"),
  ticket_add: buildRoutePath("ticket/add"),
  ticket_create: buildRoutePath("ticket/add"),
  ticket_list: buildRoutePath("ticket/list"),
  // Contestant
  contestant: buildRoutePath("contestant"),
  contestant_add: buildRoutePath("contestant/add"),
  contestant_create: buildRoutePath("contestant/add"),
  contestant_list: buildRoutePath("contestant/list"),
  // Activity
  event_activity: buildRoutePath("event_activity"),
  event_activity_add: buildRoutePath("event_activity/add"),
  event_activity_create: buildRoutePath("event_activity/add"),
  event_activity_list: buildRoutePath("event_activity/list"),
  // Judge
  judge: buildRoutePath("judge"),
  judge_list: buildRoutePath("judge/list"),
  judge_add: buildRoutePath("judge/add"),
  judge_create: buildRoutePath("judge/add"),
  // Wallet
  wallet: buildRoutePath("wallet"),
  // Form
  form: buildRoutePath("form"),
  form_add: buildRoutePath("form/add"),
  form_create: buildRoutePath("form/add"),
  form_list: buildRoutePath("form/list"),
};

const guestRouteMap = {
  landing: buildRoutePath()(),
  about: buildRoutePath("/about-us")(),
  blog: buildRoutePath("/blog")(),  
  contact: buildRoutePath("/contact-us")(),
  event: buildRoutePath("/events")(),
};

const errorRouteMap = {
  error404: "error/404",
  error500: "error/500",
};

export const routeMap = {
  auth: auth.register(authRouteMap),
  account: account.register(accountRouteMap),
  error: error.register(errorRouteMap),
  ...guestRouteMap,
};

export default function RoutingComponent() {
  let element = useRoutes([
    {
      path: "/",
      element: <GuestLayout />,
      children: [
        {
          path: routeMap.index,
          element: <Landing />,
          index: true,
        },
        {
          path: routeMap.landing,
          element: <Landing />,
        },
        {
          path: routeMap.about,
          element: <About />,
        },
        {
          path: routeMap.contact,
          element: <Contact />,
        },
      ],
    },
    {
      path: auth.basePath,
      element: <AuthLayout />,
      children: [
        {
          path: routeMap?.auth?.index,
          element: <SignIn />,
          index: true,
        },
        {
          path: routeMap?.auth?.login,
          element: <SignIn />,
        },
        {
          path: routeMap?.auth?.register,
          element: <Register />,
        },
        {
          path: routeMap?.auth?.forgotPassword,
          element: <ForgotPassword />,
        },
      ],
    },
    {
      path: account.basePath,
      element: <AccountLayout />,
      children: [
        {
          path: routeMap?.account?.index,
          element: <AccountOverview />,
          index: true,
        },
        {
          path: routeMap?.account?.overview,
          element: <AccountOverview />,
        },
        {
          path: routeMap?.account?.profile,
          element: <AccountProfile />,
        },
        {
          path: routeMap?.account?.setting,
          element: <AccountSetting />,
        },
        {
          path: routeMap?.account?.ticket,
          element: <AccountTicket />,
          children: [
            {
              element: <AccountTicketList />,
              index: true,
            },
            {
              path: routeMap?.account?.ticket_add,
              element: <AccountTicketAdd />,
            },
          ],
        },
        {
          path: routeMap?.account?.event,
          element: <AccountEvent />,
          children: [
            {
              element: <AccountEventList />,
              index: true,
            },
            {
              element: <AccountEventList />,
              path: routeMap?.account?.event_list,
            },
            {
              path: routeMap?.account?.event_add,
              element: <AccountEventAdd />,
            },
          ],
        },
        {
          path: routeMap?.account?.contestant,
          element: <AccountContestant />,
          children: [
            {
              element: <AccountContestantList />,
              index: true,
            },
            {
              element: <AccountContestantList />,
              path: routeMap?.account?.contestant_list,
            },
            {
              path: routeMap?.account?.contestant_add,
              element: <AccountContestantAdd />,
            },
          ],
        },
        {
          path: routeMap?.account?.event_activity,
          element: <AccountActivity />,
          children: [
            {
              element: <AccountActivityList />,
              index: true,
            },
            {
              element: <AccountActivityList />,
              path: routeMap?.account?.event_activity_list,
            },
            {
              path: routeMap?.account?.event_activity_add,
              element: <AccountActivityAdd />,
            },
          ],
        },
        {
          path: routeMap?.account?.judge,
          element: <AccountJudge />,
          children: [
            {
              element: <AccountJudgeList />,
              index: true,
            },
            {
              element: <AccountJudgeList />,
              path: routeMap?.account?.judge_list,
            },
            {
              path: routeMap?.account?.judge_add,
              element: <AccountJudgeAdd />,
            },
          ],
        },
        {
          path: routeMap?.account?.wallet,
          element: <AccountWallet />,
        },
        {
          path: routeMap?.account?.form,
          element: <AccountForm />,
          children: [
            {
              element: <AccountFormList />,
              index: true,
            },
            {
              element: <AccountFormList />,
              path: routeMap?.account?.form_list,
            },
            {
              path: routeMap?.account?.form_add,
              element: <AccountFormAdd />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <Error404 />,
      index: true,
    },
  ]);

  return element;
}
