function generateParams(params) {
  return params ? "?" + new URLSearchParams(params).toString() : "";
}

export const urls = {
  home: {
    get(params) {
      return `/` + generateParams(params);
    },
    route: "/",
  },
  about: {
    get(params) {
      return `/about` + generateParams(params);
    },
    route: "/about",
  },
  dashboard: {
    get(params) {
      return `/user/dashboard` + generateParams(params);
    },
    route: "/user/dashboard",
  },
  settings: {
    get(params) {
      return `/user/settings` + generateParams(params);
    },
    route: "/user/settings",
  },
  profile: {
    get(params) {
      return `/user/profile` + generateParams(params);
    },
    route: "/user/profile",
  },
  tickets: {
    get(params) {
      return `/user/tickets` + generateParams(params);
    },
    route: "/user/tickets",
  },
  contests: {
    get(params) {
      return `/user/contests` + generateParams(params);
    },
    route: "/user/contests",
  },
  contestants: {
    get(params) {
      return `/user/contestants` + generateParams(params);
    },
    route: "/user/contestants",
  },
  judges: {
    get(params) {
      return `/user/judges` + generateParams(params);
    },
    route: "/user/judges",
  },
  events: {
    get(params) {
      return `/user/event` + generateParams(params);
    },
    route: "/user/event",
  },
  wallet: {
    get(params) {
      return `/user/wallet` + generateParams(params);
    },
    route: "/user/wallet",
  },
  forms: {
    get(params) {
      return `/user/forms` + generateParams(params);
    },
    route: "/user/forms",
  },
  signup: {
    get(params) {
      return `/signup` + generateParams(params);
    },
    route: "/signup",
  },
  login: {
    get(params) {
      return `/login` + generateParams(params);
    },
    route: "/login",
  },
  eventHome: {
    get(params) {
      return `/events` + generateParams(params);
    },
    route: "/events",
  },
  eventResults: {
    get(params) {
      return `/event-results` + generateParams(params);
    },
    route: "/event-results",
  },
  home2: {
    get(params) {
      return `/home2` + generateParams(params);
    },
    route: "/home2",
  },
};
