// Form.tsx
import { useEffect } from "react";
import styled from "styled-components";
import { connectToStore } from "../../lib";
import { Outlet } from "react-router-dom";

function AccountForm() {
  return (
    <StyledForm>
      <Outlet />
    </StyledForm>
  );
}

export default connectToStore(AccountForm);

const StyledForm = styled.section`
  padding: 30px;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 15px;
  overflow-y: auto;
  @media screen and (max-width: 669px) {
    padding: 15px;
    row-gap: 15px;
  }
`;
