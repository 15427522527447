import { Tab } from "../../components/Profile";
import { useApi, useAuth } from "../../hook";
import { connectToStore } from "../../lib";

function AccountProfilePage() {
  const api = useApi();

  return (
    <>
      <Tab />
    </>
  );
}

export default connectToStore(AccountProfilePage);
