import axios from "axios";
import Qs from "qs";

// export interface APIBaseInterface {
//   setupAxios(): void;
//   getHeaders(): { [x: string]: string };
//   getToken(): string | undefined;
//   abort(): void;
// }

/**
 * @typedef {object} OptionType
 * @prop {string|undefined} token
 * @prop {number} [timeout=5000]
 * @prop {string} baseURL
 * @prop {AxiosRequestConfig} globalAxiosConfig
 * @prop {(error: AxiosError) => void} [customErrorHandler]
 * @prop {(config: AxiosRequestConfig, url: string) => void} [customRequestDebugger]
 */

/**
 *
 * @param {AxiosError} error
 */
function defaultErrorHandler(error) {
  if (
    error.response &&
    (error.response?.status === 401 || error.response?.status === 400)
  ) {
    console.error(
      "Default request Error handler::Status::" + error.response?.status
    );
  }
}

/**
 *
 * @param {AxiosRequestConfig} config
 * @param {string} url
 */
function defaultRequestDebugger(config, url) {
  console.log({
    config: { ...config, url: `${url}${config.url}` },
  });
}

/**
 * @class ApiBase
 */
export class ApiBase {
  _name;
  token;
  uri = "";
  timeout = 5000;
  /**
   * @type {OptionType}
   */
  _initializer;
  /**
   * @type {object}
   */
  headers;
  /**
   * @type {AxiosRequestConfig}
   */
  axiosConfig;

  axiosSource;
  errorHandler;
  requestDebugger;
  /**
   * @type {NonNullable<(config: AxiosRequestConfig, debug?: boolean) => Promise<AxiosResponse<any>>>}
   */
  axios;
  /**
   * @type {Partial<AxiosRequestConfig>}
   */
  _globalAxiosConfig;

  /**
   * @constructor
   * @param {OptionType} init
   * @param {string} uri
   * @returns
   */
  constructor(init, uri) {
    this._name = "ROOT";
    this.token = init?.token;
    this.uri = uri;
    this.timeout = init?.timeout;
    this._initializer = init;
    // axios headers config
    this.headers = {
      "Content-Type": "application/json",
      ...(this.token && { Authorization: `JWT ${this.token}` }),
    };
    this._globalAxiosConfig = init?.globalAxiosConfig;

    this.errorHandler = defaultErrorHandler;
    this.requestDebugger = defaultRequestDebugger;
    // Initialize error handler
    if (
      init?.customErrorHandler &&
      typeof init?.customErrorHandler === "function"
    ) {
      this.errorHandler = init.customErrorHandler;
    }
    // Initialize request debugger handler
    if (
      init?.customRequestDebugger &&
      typeof init?.customRequestDebugger === "function"
    ) {
      this.requestDebugger = init.customRequestDebugger;
    }

    this.axiosConfig = {
      headers: this?.headers,
      baseURL: init?.baseURL + this.uri,
      timeout: this?.timeout,
      paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: "brackets" });
      },
    };

    this.setupAxios();

    return this;
  }

  /**
   * @description Reconfigures axios globals
   */
  setupAxios() {
    try {
      this.axiosSource = axios.CancelToken.source();
      let _axios = axios.create({
        ...this.axiosConfig,
        cancelToken: this.axiosSource.token,
      });
      /**
       * @description Fn to help debug axios request
       * @param {AxiosRequestConfig} config
       * @param {boolean} [debug=false]
       * @returns
       */
      this.axios = async (config, debug) => {
        try {
          if (debug) {
            // For debugging purpose only
            this.requestDebugger(config, `${this.axiosConfig.baseURL}`);
          }

          return await _axios({
            ...config,
            params: this._globalAxiosConfig,
          });
        } catch (error) {
          // run the error handler
          this.errorHandler(error);
          throw error;
        }
      };
    } catch (err) {
      // console.error(err);
    }
  }

  // ================ OUR TOKEN AND HEADER ===============
  getHeaders = () => this.headers;
  getToken = () => this.token;

  /**
   * @method
   * @param {string} [message]
   */
  abort = (message = "Request cancelled. Reload page") => {
    this.axiosSource.cancel(message.toString());
    this.setupAxios();
  };
}
