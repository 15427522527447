// Event.List.js
import { connectToStore, StyledContainer } from "../../lib";
import { Button } from "@mantine/core";
import { List } from "../../components/Event";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { routeMap } from "../../route-map";

function EventListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <StyledContainer>
      <div className="flex justify-between items-baseline">
        <Button size="md"
          onClick={() =>
            navigate(routeMap?.account?.event_add, {
              state: { from: location?.pathname },
            })
          }
        >
          Create new
        </Button>
      </div>
      <List />
    </StyledContainer>
  );
}
export default connectToStore(EventListPage);
