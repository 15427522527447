// ticket.js

import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "tickets");
    this._name = "Ticket";
    return this;
  }

  /**
   * @typedef {object} PriceObj
   * @prop {number}  amount
   * @prop {string} currency
   * @prop {string} reason
   * @prop {number} in_stock
   * @prop {number} max_limit
   * @prop {number} min_limit
   * @prop {boolean} infinite_stock
   */

  /**
   * @function create
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.name
   * @param {string} args.data.description
   * @param {string} args.data.image
   * @param {string} args.data.contest
   * @param {string} args.data.date
   * @param {string} args.data.address
   * @param {PriceObj[]} args.data.prices
   *  */
  async create({ data }) {
    const res = await this.axios(
      {
        url: "/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function update
   * @param {object} args
   * @param {string} args.id
   * @param {object} args.data
   * @param {string} args.data.name
   * @param {string} args.data.description
   * @param {string} args.data.image
   * @param {string} args.data.contest
   * @param {string} args.data.date
   * @param {PriceObj[]} args.data.prices
   * @param {string} args.data.address
   * */
  async update({ id, data }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "patch",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function retrieve
   * @param {object} args
   * @param {string} args.id
   * */
  async retrieve({ id }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @function list
   * @param {object} args
   * @param {string[]} args.ids
   * */
  async list({ ids = [] }) {
    const res = await this.axios(
      {
        url: `/?${ids?.map((id) => `ids[]=${id}`)?.join("&")}`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @function remove
   * @param {object} args
   * @param {string} args.id
   * */
  async remove({ id }) {
    const res = await this.axios(
      {
        url: `/${id}`,
        method: "delete",
      },
      true
    );
    return res.data;
  }

  /**
   * @function bulkRemove
   * @param {object} args
   * @param {string} args.data
   * @param {string[]} args.data.ids
   * */
  async bulkRemove({ data }) {
    const res = await this.axios(
      {
        url: `/bulk`,
        method: "delete",
        data,
      },
      true
    );
    return res.data;
  }
}
