// Ticket.List.tsx
import { useEffect } from "react";
import { connectToStore } from "../../lib";
import { Button } from "@mantine/core";
import { List } from "../../components/Ticket";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { routeMap } from "../../route-map";

function TicketListPage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="flex justify-between items-baseline">
        <h2>All Tickets</h2>
        <Button
          onClick={() =>
            navigate(routeMap?.account?.ticket_add, {
              state: { from: location?.pathname },
            })
          }
        >
          Create new
        </Button>
      </div>
      <List />
    </>
  );
}

export default connectToStore(TicketListPage);
