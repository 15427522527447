import { useState, useEffect } from "react";
import { Tabs } from '@mantine/core';
import styled from "styled-components";
import { connectToStore } from "../../lib";
import Referrals from "./Referrals";
import BankInformation from "./BankInformation";
import Personal from "./Personal";
import { BsPersonCircle, BsBank, BsPeople } from "react-icons/bs";

const links = [
  {
    icon: <BsPersonCircle />,
    label: "Personal",
    id: "personal",
    view: <Personal />,
  },
  {
    icon: <BsBank />,
    label: "Bank information",
    id: "bank-information",
    view: <BankInformation />,
  },
  {
    icon: <BsPeople />,
    label: "Referrals",
    id: "referrals",
    view: <Referrals />,
  },
];
const ProfileNavigation = (props) => {

  return (
    <>
      
      <Tabs keepMounted={false} defaultValue={links[0]?.id}>
        <TabListStyled position="left" sx={{}}>
          {links?.map(({ id, label, view, icon }) => (
            <Tabs.Tab sx={{padding: 20, borderRadius: 0}} value={id} icon={icon}
              key={id}
            >
              {label}
            </Tabs.Tab>
          ))}          
        </TabListStyled>

        {links?.map(({ id,view }) => (<Tabs.Panel key={`view-${id}`} value={id} p="md">
         {view}
        </Tabs.Panel>))}
      </Tabs>
    </>
  );
};

export default connectToStore(ProfileNavigation);



const TabListStyled = styled(Tabs.List)`
  background: ${({ theme }) =>
    theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.colors.background[1]};
  
`;
