// Contestant.Create.tsx
import { connectToStore } from "../../lib";
import { Add } from "../../components/Contestant";

function ContestantCreationPage() {
  return (
    <>
      <h2>Add a new contestant</h2>
      <Add />
    </>
  );
}
export default connectToStore(ContestantCreationPage);
