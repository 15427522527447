import { useState } from "react";
import styled from "styled-components";
import { DeleteIcon } from "./Svgs";
import NoResult from "./NoResult";
import {Paper} from "@mantine/core";

const ListCard = ({
  children,
  deletable,
  searchable = false,
  list = [],
  emptyText = "",
}) => {
  const [processedList, setProcessedList] = useState(list);

  return (
    <ListCardStyled>
      {processedList && processedList?.length ? (
        <>
          <div className="listHead">{deletable ? <DeleteIcon /> : null}</div>
          {children({ list: processedList })}
          {/*<Pager />*/}
        </>
      ) : (
        <>
          <NoResult content={emptyText} />
        </>
      )}
    </ListCardStyled>
  );
};

export default ListCard;

const ListCardStyled = styled(Paper)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  height: 100%;
  // padding: 15px;
  border-radius: 8px;  

  .listHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  // @media screen and (max-width: 480px) {
  //   margin-left: auto;
  //   margin-right: auto;
  // }
`;
