import {useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { routeMap } from "../../route-map";
import {Button, Title, Text} from "@mantine/core"
import {connectToStore} from "../../lib"
import {BsFillSignpostSplitFill} from "react-icons/bs";

function Error404({SessionState}) {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <div className="mb-4 h-[85vh] flex-col flex items-center justify-center gap-y-[8px]"> 
        <BsFillSignpostSplitFill size={75} />
        <Title order={1}>404</Title>
        <Text size="sm">Page not found</Text>
      </div>
      <Button size="md" onClick={() => navigate(SessionState?.isLoggedIn ? routeMap?.account?.index : routeMap?.landing)}>Go Home</Button>
    </PageContainer>
  );
}

export default connectToStore(Error404)
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
