import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Card from "../Card";
import { Button, TextInput, Select, Switch, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { UploadManager } from "../FormElement";
import { InfoIcon2 } from "../Svgs";
import { connectToStore } from "../../lib";

const validationSchema = Yup.object().shape({});

const AddTicket = () => {
  const form = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: null,
      data: "",
      contest: "",
      image: "",
    },
  });
  return (
    <Card title={"Create new ticket group"}>
      <TextInput label={"Group Title"} placeholder="e.g VIP I" />
      <Textarea label={"Description"} placeholder={"Ticket Description"} />

      <UploadManager
        max={1}
        onFinish={async (value) => {
          console.log({ value, form });
          {
            /*form?.setFieldValue("logo", value);*/
          }
        }}
        label={"Background Image"}
      />

      <Button className="w-full" size="md">
        Create Ticket
      </Button>
    </Card>
  );
};

export default connectToStore(AddTicket);
