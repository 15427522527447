import styled, { css } from "styled-components";
import Banner from "./Banner";
import WorkBox from "./WorkBox";
import { useLocation } from "react-router-dom";
import { Button } from "@mantine/core";

const Works = () => {
  const { pathname } = useLocation();
  return (
    <WorkStyled location={pathname}>
      <h2 className="title">How it works</h2>
      <WorkBox />
      <Banner />
      <span className="expectation">Exceeding your expectations</span>
      <h2 className="title">Creating the best content</h2>
      <Button className="" size={"lg"}>
        Get started
      </Button>
    </WorkStyled>
  );
};

export default Works;

const WorkStyled = styled.section`
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 68px;
  margin-bottom: 57px;
  justify-content: center;
  align-items: center;

  .expectation {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    margin-bottom: 22px;
  }
  .btn {
    background: #f78f08;
    box-shadow: 0px 4px 20px rgba(241, 155, 0, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px 15px 30px;
    border: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }

  > .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 47px;
    text-align: center;
    letter-spacing: -0.04em;
    margin-bottom: 69px;
  }
`;
