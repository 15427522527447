import * as Yup from "yup";
import { useFormik } from "formik";
import { connectToStore, parseErrorMessage, patterns} from "../../lib";
import { useEffect, useRef, useState } from "react";
import {BsLightbulb} from "react-icons/bs";
import { Button, Textarea, Grid, Divider, TextInput, Text, Group, HoverCard, UnstyledButton, Code, Select,Paper, Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { DatePicker, TimeInput } from "@mantine/dates";
import Card from "../Card";
import { UploadManager } from "../FormElement";
import { Selector as EventSelector } from "../Event";
import { useApi } from "../../hook";

function AddActivity({
  implicitEvent = false
}) {
  const api = useApi();
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    if (!isMounted?.current) isMounted.current = true;

    fetchEvents();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validationSchema = Yup.object({
     ...(!implicitEvent ? {event: Yup.string().required("Name field is required")} : {}),
    name: Yup.string()
        .min(5, "Name expects a minimum of 5 characters")
        .required("Name field is required"),
    slug: Yup.string()
        .matches(patterns?.slug, "Words in slug field must be underscore (_) separated")
        .required("Slug field is required"),
    description: Yup.string()
        .min(20, "Name expects a minimum of 20 characters")
        .required("Description field is required"),
    elimination_strategy: Yup.string()
        .required("Elimination strategy field is required")
  });

  async function fetchEvents() {
    try {
      const list = await api.Event.list();
      console.log({ list });
      const { results, ...paging } = list;
      setEventList(results);
      setPagination(paging);
    } catch (error) {
      parseErrorMessage(error);
    } finally {
    }
  }

  function parsedEventList() {
    return eventList?.map((event) => ({
      label: event?.title,
      value: event?.id,
    }));
  }

  const form = useForm({
    initialValues: {
      logo: "",
      name: "",
      description: "",
      slug: "",
      about: "",
      elimination_strategy: "",
      location: "",
      banner: "",
      ...(!implicitEvent ? {event: ""} : {})
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(validationSchema)
  });

  async function onSubmit () {
    try {
      isMounted.current && setLoading(true)
      const values = form?.values;
      console.debug(values);
    } catch (error) {
      console.error(error);
    } finally {
      isMounted.current && setLoading(false)
    }
  }

  return (<>
    <Stack spacing="lg">
      <Paper p="md" shadow="xs"  > 
        <Grid grow  align="flex-start" gutter="lg">
          <Grid.Col span={"auto"} sx={{minWidth: 300}}>
            <Stack spacing="sm" >
              <UploadManager
                label="Logo Image"
                max={1}
                onFinish={async (value) => {
                  form?.setFieldValue("logo", value);
                }}
                size="md"
              />

              <UploadManager
                label="Banner Image"
                max={1}
                size="md"
                onFinish={(value) => form?.setFieldValue("banner", value)}
              />

              {!implicitEvent? <EventSelector size="md" onFinish={(value) =>form?.setFieldValue('event', value)}/> : null}
            </Stack>
          </Grid.Col>

          <Grid.Col span={8}>
            <Stack spacing={8} >
              <TextInput
                label="Enter a name for your event activity"
                placeholder="Name of activity"
                withAsterisk
                size="md"
                {...form.getInputProps('name')}
                aria-label="Activity name"
              />

              <Textarea
                label="Description"
                withAsterisk
                size="md"
                {...form.getInputProps('description')}
                placeholder="Describe activity"
                aria-label="event activity description"
              />

              <TextInput
                label="Elimination strategy"
                size="md"
                withAsterisk
                {...form?.getInputProps("elimination_strategy")}
                placeholder="Choose an elimination strategy"
              />

              <TextInput
                label="Slug"
                size="md"
                withAsterisk       
                placeholder="Activity title"
                {...form.getInputProps('slug')}
              />
            </Stack> 
          </Grid.Col>
        </Grid>  
      </Paper>
    
      <Stack spacing={16} >                
        <Group position="apart" sx={{position:'relative'}}>
          <Text size="sm">Arrival </Text>
          <HoverCard width={280} shadow="md" position="right-end">
            <HoverCard.Target>
              <UnstyledButton className="opacity-[.75]"><BsLightbulb key="event-arrival-hint"/></UnstyledButton>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">
                Enter data for when the event will commence
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </Group>
        <Paper shadow="xs" p="sm">
          <Group position="apart">
            <TimeInput size="md" className="grow" label="Time" clearable/>
            <DatePicker size="md" className="grow" placeholder="Event start date (optional)" label="Date" clearable/>
          </Group>
        </Paper>

        <Group position="apart" sx={{position:'relative'}}>
          <Text size="sm">Departure (Optional)</Text>
          <HoverCard width={280} shadow="md" position="right-end">
            <HoverCard.Target>
              <UnstyledButton className="opacity-[.75]"><BsLightbulb key="event-departure-hint"/></UnstyledButton>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">
                Enter data for when the event will end
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </Group>
        <Paper shadow="xs" p="sm">
          <Group position="apart">
            <TimeInput size="md" className="grow" label="Time" clearable/>
            <DatePicker size="md" className="grow" placeholder="Enter end date (optional)" label="Date" clearable/>
          </Group>
        </Paper>            

        <Group position="right">
          <Button
            size={"md"}
            disabled={!form?.isValid}
            loading={loading}
          >
            Add Activity
          </Button>
        </Group>
      </Stack>
    </Stack>
  </>);
}

export default connectToStore(AddActivity);
