import styled, { css } from "styled-components";
import banner from "../../images/banner.png";
import questionIcon from "../../images/icons/question.png";
import { useLocation } from "react-router-dom";
import { Button } from "@mantine/core";

const Banner = (props) => {
  const { pathname } = useLocation();
  return (
    <BannerStyled pathname={pathname}>
      <div className="p-6">
        <div className="mb-4">
          <h2 className="title">Best content deals for 2021</h2>
          <p>
            Create and manage the your content with the best content <br />{" "}
            management system
          </p>
        </div>
        <Button size="md">Get the deal</Button>
      </div>
      <img src={banner} className="banner-img" alt="" srcSet="" />
    </BannerStyled>
  );
};

export default Banner;

const BannerStyled = styled.div`
  ${({ pathname }) =>
    pathname === "/about/" || pathname === "/about"
      ? css`
          background-color: #7dd040;
        `
      : pathname === "/home2/" || pathname === "/home2"
      ? css`
          background: linear-gradient(
            180deg,
            rgba(8, 112, 247, 0.81) 6.8%,
            #243ac5 78.37%
          );
        `
      : css`
          background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
        `}
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 220px;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  position: relative;
  max-width: 768px;
  width: 100%;

  .banner-img {
    height: 400px;
    position: absolute;
    right: 10px;
  }

  .cta {
    margin: 30px 0 26px 54px;
    width: auto;
    background-color: #f78f08;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 4px 20px rgba(241, 155, 0, 0.2);
    border-radius: 4px;

    span {
      margin-left: 9px;
      color: #ffffff;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-top: 15px;
  }

  .title {
    font-weight: 800;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: -0.01em;
    color: #ffffff;
  }

  @media screen and (max-width: 480px) {
    text-align: center;
    height: fit-content;
    > img {
      display: none;
    }
    br {
      display: none;
    }
    .cta {
      margin: 30px 0 0 0;
    }
    div {
      padding: 26px 23px;
    }
  }
`;
