import React from "react";
import styled from "styled-components";
import { Button, Alert, Code } from '@mantine/core';
import {BsFillExclamationTriangleFill} from "react-icons/bs"

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <PageContainer role="alert">
      <Alert icon={<BsFillExclamationTriangleFill size={16}/>} title="Bummer!"  color="red">
        <Code color={'red'}>{error?.message}</Code>
      </Alert>
      <div className="flex justify-end w-full py-4">
        <Button onClick={resetErrorBoundary}>Reload app</Button>
      </div>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: auto;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
  h1 {
    margin-bottom: 12px;
  }
  .error-message {
    font-weight: 600;
    color: #f38;
    min-height: 200px;
    background-color: #eec;
    padding: 12px;
    border-radius: 8px;
    margin: 0 0 12px;
  }
`;
