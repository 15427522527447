// Wallet.tsx

import { useEffect } from "react";
import styled from "styled-components";
import { connectToStore, StyledContainer } from "../../lib";
import {Container} from "@mantine/core"
import {
  WalletSummary,
  ListTransaction,
} from "../../components/WalletComponent";

function AccountWallet() {
  return (
    <StyledContainer>
      <Container className="lg:grow-0 grow">
        {/*Wallet summary*/}
        <WalletSummary />
      </Container>

      <div className="grow">
        <ListTransaction />
      </div>
    </StyledContainer>
  );
}

export default connectToStore(AccountWallet);
