import styled from "styled-components";
import rafikiImage from "../images/rafiki.png";
const NoResult = ({ content }) => (
  <NoResultStyled>
    <img src={rafikiImage} alt="" />
    <p>No Result</p>
    <span>{content}</span>
  </NoResultStyled>
);

export default NoResult;

const NoResultStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
  flex-grow: 1;
  p {
    font-weight: 700;
    margin-top: 37px;
    margin-bottom: 7px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #8c8c8c;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8c8c8c;
  }

  @media screen and (max-width: 480px) {
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    span {
      width: 70%;
    }
  }
`;
