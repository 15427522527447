import * as Yup from "yup";
import { useFormik } from "formik";
import { connectToStore } from "../../lib";
import Card from "../Card";
import { Button, dTextInput, Select, Textarea } from "@mantine/core";
import { UploadManager } from "../FormElement";

function AddContestant() {
  const form = useFormik({
    initialValues: {
      full_name: "",
      email_address: "",
      about: "",
      gender: "",
      avatar: "",
    },
    onSubmit: async (values, { setIsSubmitting }) => {
      try {
        console.debug(values);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  return (
    <Card title="Add contestant">
      <dTextInput label="Full name" />
      <dTextInput label="Email address" type="email" />
      <Textarea label="Bio" />
      <Select label="Gender" data={[]} />
      <UploadManager label="Avatar" />
      <Button onClick={form?.handleSubmit} disabled={!form?.isValid}>
        Finish
      </Button>
    </Card>
  );
}

export default connectToStore(AddContestant);
