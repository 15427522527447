import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {import("../../types").Reducer.Session} defaultState
 */
const initialState = {
  isLoggedIn: false,
  access: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    $SET: (state, action) => {
      return { state, ...action.payload };
    },
    $CLEAR: (state, action) => {
      console.log({ action });
      return initialState;
    },
  },
});

export const actions = sessionSlice.actions;
export default sessionSlice.reducer;
