import React from "react";
import { hydrate, render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/reducer";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { RecoilRoot } from "recoil";
import App from "./App";
import { APIProvider } from "./context/api.context";
import { AuthProvider } from "./context/auth.context";
import { SettingProvider } from "./context/setting.context";


const main = (
  <React.StrictMode>
    
      <Provider store={store}>
        <APIProvider>
          <AuthProvider>
            <SettingProvider>
              <RecoilRoot>
                {/* @ts-ignore */}
                <PersistGate persistor={persistor}>
                  <App />
                </PersistGate>
              </RecoilRoot>
            </SettingProvider>
          </AuthProvider>
        </APIProvider>
      </Provider>
  </React.StrictMode>
);


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(main, rootElement);
} else {
  render(main, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
