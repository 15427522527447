import Navbar from "../components/Navbar.Default";
import Works from "../components/Home/Works";
import Footer from "../components/Footer.Default";
import Ideas from "../components/Home/Ideas";
import Team from "../components/Team.Default";

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <Ideas />
      <Works />
      <Footer />
    </>
  );
};

export default ContactUs;
