import { useState, useRef, useEffect } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { Link, Navigate } from "react-router-dom";
import styled from "styled-components";
import { TextInput, Button, Text, Container, Stack, Group, Title } from "@mantine/core";
import Logo from "../../components/Logo";
import { MarkIcon } from "../../components/Svgs";
import { parseErrorMessage, connectToStore, patterns } from "../../lib";
import { toast } from "react-hot-toast";
import useApi from "../../hook/useApi";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { routeMap } from "../../route-map";
import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string().email().required("Email address is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(patterns?.password, "Password must be alphanumeric and must contain at least a capital-case character")
    .required("Password is required"),
  re_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm new password"),
  referrer: Yup.string(),
});

const Register = ({ SessionDispatch }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef();
  const api = useApi();

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function registerUser() {
    toast.loading("Authenticating...", {
      id: "register-user-loading",
    });
    try {
      const response = await api.User.register({ data: formik.values });

      toast.success("Successfully registered", {
        id: "register-user-success",
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      });
      Navigate({
        to: routeMap?.auth?.login,
        replace: true,
      });
    } catch (err) {
      parseErrorMessage(err);
    } finally {
      toast.dismiss("register-user-loading");
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      re_password: "",
      referrer: "",
    },
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: true,
    onSubmit: registerUser,
    validationSchema,
  });

  return (
    <Container  p="xl">    
      <Stack spacing={"xl"} sx={{ height: "100vh", justifyContent:"center" }} >
        <Group spacing={"xl"} align="flex-start" >
            <Stack spacing="sm" className="mr-auto ">
              <Logo style={{width: 45}}/>  
              <Title className="my-4">Ready! Go!</Title>
              <Stack py="lg" >
                <Text>
                  Ready to try us? Create an account to get started.
                </Text>
                <div className="flex gap-x-[8px] items-center">
                  <MarkIcon />
                  <span>Host any tournament, contest or any competitons</span>
                </div>
                <div className="flex gap-x-[8px] items-center">
                  <MarkIcon />
                  <span>Get 24/7 support</span>
                </div>
                <div className="flex gap-x-[8px] items-center">
                  <MarkIcon />
                  <span>Manage your receipts and transactions per contest</span>
                </div>
                <div className="flex gap-x-[8px] items-center">
                  <MarkIcon />
                  <span>Host any tournament, contest or any competitons</span>
                </div>
              </Stack>
            </Stack>

            <form onSubmit={formik.handleSubmit} className="grow lg:max-w-[350px]">
              <Stack spacing={"lg"} >
                <Stack spacing={"md"} >
                  <TextInput
                    size="md"
                    label="Username"
                    placeholder="Username"
                    id="username-textinput"
                    error={
                      formik?.touched?.username
                        ? formik?.errors?.username
                        : undefined
                    }
                    onChange={formik.handleChange("username")}
                    onBlur={formik.handleBlur("username")}
                  />

                  <TextInput
                    size="md"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    id="email-textinput"
                    error={formik?.touched?.email ? formik?.errors?.email : false}
                    label="Email address"
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                  />

                  <TextInput
                    size="md"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    placeholder="Password"
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    error={
                      formik?.touched?.password ? formik?.errors?.password : false
                    }
                    id="password-textinput"
                    rightSection={
                      <span
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <IoEye></IoEye> : <IoEyeOff></IoEyeOff>}
                      </span>
                    }
                  />
                  <TextInput
                    size="md"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm password"
                    id="re_password-textinput"
                    error={
                      formik?.touched?.re_password
                        ? formik?.errors?.re_password
                        : undefined
                    }
                    onChange={formik.handleChange("re_password")}
                  />
                  <TextInput
                    size="md"
                    type="text"
                    placeholder="Referrer code"
                    id="referral-code-textinput"
                    error={
                      formik?.touched?.referrer
                        ? formik?.errors?.referrer
                        : undefined
                    }
                    label="Referral code"
                    onChange={formik.handleChange("referrer")}
                    onBlur={formik.handleBlur("referrer")}
                  />
                </Stack>

                <Button
                  type="submit"
                  size="md"
                  loading={formik.isSubmitting}
                  disabled={!formik.isValid}
                  className="w-full"
                >
                  Create an account
                </Button>
                <Text className="my-2 text-center">
                  Already have an account? {" "}
                  <Text color={'brand'} component={Link} to={routeMap?.auth?.login}>
                    Sign In
                  </Text>
                </Text>
              </Stack>
            </form>
        </Group>
      </Stack>
    </Container>
  );
};

export default connectToStore(Register);

const SignUpStyled = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .col-1 {
    /* width: 379px; */
    outline: 1px dashed red;
  }
  .top {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    width: 100%;
  }
  .contentWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    padding: 20px 0;
    flex-grow: 1;
    justify-content: space-between;
    .header {
      width: 100%;
    }

    .header,
    .form-container {
      padding: 30px;
      flex-grow: 1;
      width: auto;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
    .form-container {
      min-width: 350px;
      display: flex;
      flex-direction: column;
      /* background-color: #acacac; */
      border-radius: 8px;
      .info {
        margin-top: 30px;
      }
      .orange {
        color: #f78f08;
        display: inline;
      }
    }
    .title {
      font-weight: 700;
      font-size: 54px;
      line-height: 48px;
      /* letter-spacing: -0.04em; */
      /* color: #243ac5; */
    }
    .subtitle {
      /*font-family: "Inter";*/
      font-style: normal;
      font-weight: 400;
      margin-top: 15px;
      font-size: 20px;
      margin-bottom: 30px;
      line-height: 24px;
      // color: #1d1d63;
      opacity: 0.7;
    }
    .row {
      display: flex;
      margin-bottom: 15px;
      span {
        font-weight: 400;
        margin-left: 8px;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 19px;
        // color: #1d1d63;
        opacity: 0.5;
      }
    }
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    .contentWrapper {
      flex-direction: column;
      align-items: center;
    }
  }
`;
