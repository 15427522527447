// ResetPassword.jsx
import { useState, useEffect, useRef } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Button, Switch, TextInput, Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { connectToStore, parseErrorMessage, patterns } from "../../lib";
import Card from "../Card";
import { useApi } from "../../hook";

const validationSchema = Yup.object().shape({
  current_password: Yup.string()
    .min(8)
    .required("Current password is required"),

  new_password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .matches(
      patterns?.password,
      "Must contain at least one uppercase character and special characters"
    )
    .required("New password is required"),

  re_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Password must match")
    .required("Confirm new password"),
});

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const form = useForm({
    initialValues: {
      current_password: "",
      new_password: "",
      re_new_password: "",
    },
    validateInputOnBlur: true,
    validateInputOnChange: false,
    validate: yupResolver(validationSchema),
  });

  useEffect(() => {
    if(!isMounted.current)
      isMounted.current = true;

    return () => {
      isMounted.current = false
    }
  }, [])

  async function changePassword(data, { setIsSubmitting }) {
    try {
      isMounted.current && setLoading(true);
      const response = await api.User?.changePassword({ data });
      console.debug({ response });

      toast.success("Your password has been changed", {
        id: "change-password-success",
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      });
    } catch (error) {
      parseErrorMessage(error);
    }finally{
      isMounted.current && setLoading(false);
    }
  }

  return (
    <>
      <Card title={"Reset Password"}>
        <form onSubmit={form.onSubmit(changePassword)}>
          <Stack>
            <TextInput size="md"
              placeholder={".".repeat(8)}
              {...form.getInputProps("current_password")}           
              tabIndex={1}
              label={"Current password"}
              autocomplete={"current-password"}
              autocapitalize={"off"}
              type="password"
            />
            <TextInput
             {...form.getInputProps("new_password")}  
              placeholder={".".repeat(8)}
              size="md"
              tabIndex={2}
              label={"New password"}
              type={showPassword ? "text" : "password"}
              autocomplete={"new-password"}
              autocapitalize={"off"}
              rightSlot={() => (
                <span
                  style={{
                    cursor: "pointer",
                    display: "inline-flex",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <IoEye></IoEye> : <IoEyeOff></IoEyeOff>}
                </span>
              )}
            />
            <TextInput size="md"
              type={showPassword ? "text" : "password"}
              {...form.getInputProps("re_new_password")}           
              tabIndex={3}
              placeholder={".".repeat(8)}
              label={"Confirm new password"}
              autocomplete={"off"}
              autocapitalize={"off"}
            />
          <Button
            type="submit"
            loading={loading}
            disabled={!form.isValid()}
            tabIndex={4}
            className="w-full"
            size="md"
          >
            Reset password
          </Button>
          </Stack>
        </form>
      </Card>
    </>
  );
}

export default connectToStore(ResetPassword);
