import styled from "styled-components";
import { MarkIcon } from "../Svgs";
import PaymentImage from "../../images/payment.png";
import InteractiveImage from "../../images/interactive.png";

const InteractiveContent = () => (
  <InteractiveContentStyled>
    <div className="pay-go">
      <img src={PaymentImage} alt="" />
      <div>
        <div>
          <h2 className="title">Pay as you go</h2>
          <p className="subtitle">
            We offer flexible and competitive pricing plans. No more excuses
            anymore. Scale your contest and reach your desired audience
          </p>
        </div>
        <div className="row">
          <span>Percentage</span>
          <MarkIcon fill="#37C351" />
        </div>
        <div className="row">
          <span>Subscription plans</span>
          <MarkIcon fill="#37C351" />
        </div>
      </div>
    </div>
    <div className="interactive-content">
      <div className="text-content">
        <h2 className="title">Interactive contents</h2>
        <p className="subtitle">
          Keep your audience entertained with cool and interactive contents. We
          offer video streaming of events
        </p>
      </div>
      <div className="image-container">
        <img src={InteractiveImage} alt="" />
      </div>
    </div>
  </InteractiveContentStyled>
);

export default InteractiveContent;

const InteractiveContentStyled = styled.div`
padding-top: 60px;
  .title {
    font-size: 45px;
    line-height: 48px;
    letter-spacing: -0.04em;
  }
  .interactive-content {
    display: flex;
    width: 100%;
    height: 70vh;
    overflow-y: hidden;
    margin-top: 60px;
    .text-content {
      width: 280px;
      margin-left: 150px;
    }
    .image-container {
      flex-grow: 1;
      position: relative;
      img {
        position: absolute;
        top: -50px;
        right: 0;
      }
    }
  }
  .subtitle {
    margin-top: 15px;
    margin-bottom: 25px;
    width: 89%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
  }
  .pay-go {
    padding-right: 180px;
    display: flex;
    justify-content: flex-end;
    img {
      margin-right: 114px;
    }
    > div {
      > div {
        margin-bottom: 25px;
      }
      margin-top: 80px;
      width: 393px;
      .row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 18px;
        span {
          margin-right: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .pay-go {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      flex-direction: column;
      > div {
        width: 100%;
      }
      img {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .interactive-content {
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
      .text-content {
        margin-left: 0;
        width: 100%;
        .title,
        .subtitle {
          width: 100%;
        }
      }
      .image-container {
        img {
          position: relative;
          top: 0;
          right: 0;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
`;
