import styled from "styled-components";
import image1 from "../images/image1.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";

const Team = () => {
  return (
    <TeamStyled>
      <h2>Team</h2>
      <div className="members">
        <img src={image1} alt="" srcSet="" />
        <img src={image2} alt="" srcSet="" />
        <img src={image3} alt="" srcSet="" />
      </div>
      <button>
        <span>Join Us</span>
      </button>
    </TeamStyled>
  );
};

export default Team;

const TeamStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;

  button {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f78f08;
    border: none;
    box-shadow: 0px 4px 20px rgba(241, 155, 0, 0.2);
    border-radius: 4px;
    padding: 15px 20px 15px 30px;
  }

  button span {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 0;
    margin: 0;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #333333;
    margin-bottom: 28px;
  }
  .members {
    display: flex;
    justify-content: center;
  }
  .members img {
    margin-right: 30px;
  }

  @media screen and (max-width: 480px) {
    .members {
      flex-direction: column;
      img {
        margin-bottom: 25px;
      }
    }
  }
`;
