// ListContest.jsx
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { connectToStore, parseErrorMessage } from "../../lib";
import ListCard from "../ListCard";
import { useApi } from "../../hook";

function ListContest() {
  const api = useApi();
  const isMounted = useRef(true);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    if (!isMounted?.current) isMounted.current = true;

    fetchContest();
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function fetchContest() {
    try {
      const list = await api.Contest.list();
      console.log({ list });
      const { results, ...paging } = list;
      setList(results);
      setPagination(paging);
    } catch (error) {
      parseErrorMessage(error);
    } finally {
    }
  }

  return (
    <>
      <ListCard list={list} emptyText="All contest you create will appear here">
        {({ link }) => <> </>}
      </ListCard>
    </>
  );
}

export default connectToStore(ListContest);

const StyledContainer = styled.section``;
