import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Outlet, Navigate } from "react-router-dom";
import styled from "styled-components";
// User defined imports
import { useAuth, useSetting } from "../hook";
import { routeMap } from "../route-map";
import { connectToStore } from "../lib";
import { Account } from "../components/Navigation";

const AccountLayout = ({ SessionState }) => {
  const { startSession } = useAuth();
  const { showSideNavigation, onCloseSideNavigation } = useSetting();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      window &&
      window?.matchMedia("only screen and (max-width: 669px)").matches
    ) {
      onCloseSideNavigation();
    }
  }, [pathname]);

  useEffect(() => {
    if (SessionState?.isLoggedIn) {
      startSession();
    }
  }, [SessionState]);

  return SessionState?.isLoggedIn ? (
    <StyledContainer>
      {/*START: top-navigation-bar*/}
      <div className="account-top-nav-container">
        <Account.Top />
      </div>
      {/*END: top-navigation-bar*/}
      <main className="account-content-container">
        {/*START: side-navigation-bar*/}
        <div
          className={`account-lside-nav-container ${
            !showSideNavigation ? "hide" : "visible"
          }`}
        >
          <Account.LeftSide />
        </div>
        {/*END: side-navigation-bar*/}

        {/*START: main-content*/}
        <div
          className={`account-content-main-container ${
            showSideNavigation ? "hide-overflow" : undefined
          }`}
        >
          <Outlet />
        </div>
        {/*END: main-content*/}
      </main>
    </StyledContainer>
  ) : (
    <Navigate to={routeMap?.auth?.login} replace={true} />
  );
};

export default connectToStore(AccountLayout);

const background = ({ theme }) =>
  theme.colorScheme === "dark"
    ? theme.colors.dark[7]
    : theme.colors.background[0];

const StyledContainer = styled.section`
  min-height: 100vh;
  max-height: 100vh;
  background: ${background};
  .account-top-nav-container {
    background: ${background};
    position: sticky;
    top: 0;
    min-height: 75px;
    
    z-index: 3;
   /* border-bottom: 1px solid
      ${({ theme }) =>
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.background[3]};*/
  }
  .account-content-container {
    display: flex;
    flex-grow: 1;
    max-height: 100%;
    height: calc(100vh - 75px);
    z-index: 1;
    overflow-y: scroll;

    .account-lside-nav-container {
      position: sticky;
      top: 0;
      margin-left: 0;
      min-width: 250px;
      width: 250px;
      z-index: 2;
      height: 100%;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;
      min-height: 100%;
      max-height: 100%;
      transition: margin 0.5s ease-in-out, opacity 0.75s ease-in-out,
      transform 0.65s ease-in-out;
      overflow-y: auto;
      background: ${background};

      @media screen and (max-width: 664px) {
        min-width: 100%;
      }

      &.hide {
        margin-left: -250px;
        transform: translateX(-100%);
        opacity: 0;
        @media screen and (max-width: 664px) {
          position: relative;
          top: auto;
          margin-left: -100%;
        }
      }
      @media screen and (max-width: 664px) {
        &.visible:before {
          background: #00000075;
          top: 0;
          width: 100%;
          height: 100%;
          left: 0;
          content: "";
          position: fixed;
          filter: blur(4px);
        }
      }
    }
  

    .account-content-main-container {
      flex: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 1;
      border-radius: 10px;
      transition: all 0.5s ease-in-out;
      min-height: 100%;
      max-height: 100%;
      overflow-y: auto;
      background-color: ${({ theme }) =>
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.background[1]};
      @media screen and (max-width: 1096px) {
        flex-shrink: 1;
        &.hide-overflow {
          overflow: hidden;
        }
      }
    }
  }
`;
