// Event.Add.js
import { connectToStore } from "../../lib";
import { Add } from "../../components/Event";

function EventCreationPage() {
  return (
    <>
      <Add />
    </>
  );
}
export default connectToStore(EventCreationPage);
