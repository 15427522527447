import {
  BsReceiptCutoff,
  BsArrowUpRightSquare,
  BsCaretDownFill,
  BsInfoCircleFill,
  BsCaretUpFill,
  BsHr,
  BsTrophy,
  BsCalendarDate,
} from "react-icons/bs";
import MUIButton from "@mui/material/Button";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
import {Paper, UnstyledButton, Button,Group, Text} from "@mantine/core";
import { useApi } from "../../hook";
import { connectToStore, parseErrorMessage } from "../../lib";
import {routeMap} from "../../route-map";
import {
  CardInfoIcon,
  DecrementIcon,
  IncrementIcon,
} from "../../components/Svgs";

function AccountOverview() {
  const isMounted = useRef(true);
  const api = useApi();
  const [analytics, setAnalytics] = useState();

  useEffect(() => {
    if (!isMounted?.current) isMounted.current = true;

    (async () => {
      try {
        const data = await api.Analytics.dashboard();
        isMounted?.current && setAnalytics(data);
      } catch (error) {
        parseErrorMessage(error, true, "analytics");
      } finally {
      }
    })();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <OverviewStyled>
      <div id="welcome">
        <h2 className="mb-4">Welcome, John</h2>
        {analytics ? (
          <div className="welcome">
            {/*EVENTS*/}
            <MetriCard className="events metric-card">
             
              <div className="titleBx">
                <p className="title">{analytics?.event_count}</p>
                <BsCalendarDate size={45} />
              </div>
              <Text component={Link} className="flex gap-x-[8px]"
                 to={routeMap?.account?.event}                  
               >
                Events
                <BsArrowUpRightSquare className="external-link" />          
               </Text>
            </MetriCard>

            {/*CONTESTS*/}
            <MetriCard className="contests metric-card">
              <div className="titleBx">
                <p className="title">{analytics?.contest_count}</p>
                <BsTrophy size={45} />
              </div>
               <Text component={Link} className="flex gap-x-[8px]"
                 to={routeMap?.account?.event_activity} 
                 
               >
                Activities
                <BsArrowUpRightSquare className="external-link" />          
               </Text>
            </MetriCard>

            {/*TICKET*/}
            <MetriCard className="tickets metric-card">
              
              <div className="titleBx">
                <p className="title">{analytics?.ticket_total_count}</p>
                <BsHr size={45} />
              </div>
              <div className="status ">
                <div className="flex gap-x-[2px] items-center">
                  <span>{analytics?.ticket_in_count}</span>
                  <BsCaretUpFill style={{ fill: "green" }} />
                  <span>{analytics?.ticket_out_count}</span>
                  <BsCaretDownFill style={{ fill: "red" }} />
                </div>
              </div>
              <Text component={Link} className="flex gap-x-[8px]"
                 to={routeMap?.account?.ticket}                  
               >
                Tickets
                <BsArrowUpRightSquare className="external-link" />          
               </Text>
            </MetriCard>

            {/*TRANSACTION*/}
            <MetriCard className="transactions metric-card">              
              <div className="titleBx">
                <p className="title">{analytics?.transaction_total_count}</p>
                <BsReceiptCutoff size={45} />
              </div>
              <div className="status">
                <div className="flex items-center gap-x-[2px]">
                  <span>{analytics?.transaction_credit_count}</span>
                  <BsCaretUpFill style={{ fill: "green" }} />

                  <span>{analytics?.transaction_debit_count}</span>
                  <BsCaretDownFill style={{ fill: "red" }} />
                </div>
              </div>
              <Text component={Link} className="flex gap-x-[8px]"
                 to={routeMap?.account?.transactions}                  
               >
                Transactions
                <BsArrowUpRightSquare className="external-link" />          
               </Text>
            </MetriCard>
          </div>
        ) : null}
      </div>
    </OverviewStyled>
  );
}

export default connectToStore(AccountOverview);

const MetriCard = styled(Paper)`
  border-radius: 8px;
    padding: 20px;
    flex-grow: 1;
    position: relative;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    min-width: 200px;
&.events {
      svg{
        fill: hsl(174.4, 53.6%, 56.9%)
      };
    }
&.contests {
      svg{fill: hsl(282, 51.7%, 37.3%)};
    }
    &.tickets {
      svg{fill: hsl(220, 79.7%, 56.9%)};
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &.transactions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg{fill: hsl(211.4, 67.7%, 54.5%)}
    }
    .status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 2px 10px;

      svg {
        margin-left: 2px;
        margin-right: 4px;
      }
      > svg {
        margin-right: -10px;
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
      }
    }
`;

const OverviewStyled = styled.section`
  overflow-y: auto;

  #welcome {
    padding: 30px;
  }
  .external-link {
    color: currentColor;
    font-size: 18px;
    margin-left: 4px;
  }
  .main {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #1d1d63;
    margin-bottom: 15px;
  }

  .welcome {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    color: #333;    
  }

  .welcome .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 59px;
  }

  .welcome .titleBx {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .details {
    display: flex;
    align-items: baseline;
    .subtitle {
      font-weight: 300;
      font-size: 18px;
      line-height: 14px;
    }
    img {
      width: 10px;
      height: 10px;
      position: relative;
      top: -3px;
    }
  }

  .analytics {
    margin-left: 30px;
    margin-top: 31px;
    .main {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 480px) {
    .welcome {
      flex-direction: column;
      > div {
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
`;
