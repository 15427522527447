// index.js
/**
 *	Navigation single file import
 */

import { lazy } from "react";

export const Account = {
  Top: lazy(() => import("./AccountTopNavigation")),
  LeftSide: lazy(() => import("./AccountLeftSideNavigation")),
  // RightSide: lazy(()=>import("./AccountSideNavigation"))
};

const navigations = {
  Account,
};

export default navigations;
