import { useApi, useAuth } from "../../hook";
import { connectToStore } from "../../lib";
import {
  General,
  Security,
  ConnectedAccount,
  ResetPassword,
  DeleteAccount,
} from "../../components/Setting";
import styled from "styled-components";

function AccountSetting() {
  const api = useApi();

  return (
    <StyledContainer>
      <div className="lg:w-[50%] w-[100%]">
        <General />
        <Security />
        <ConnectedAccount />
      </div>
      <div className="lg:grow-0 grow">
        <ResetPassword />
        <DeleteAccount />
      </div>
    </StyledContainer>
  );
}

export default connectToStore(AccountSetting);

const StyledContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  width: 100%;
  column-gap: 30px;
  overflow-y: auto;

  @media screen and (max-width: 669px) {
    padding: 15px;
    row-gap: 15px;
  }
`;
