import { useAppSelector } from "./useRedux";

function useSession() {
  /**
   *
   * @param {import("../store/reducer").reducers} state
   * @returns
   */
  const state = (state) => {
    console.log(state);
    return state.session;
  };

  /**
   * @type {import("../store/reducer/session").Session} session
   */
  const session = useAppSelector(state);

  return {
    ...session,
  };
}

export default useSession;
