import { createContext, useState, useEffect } from "react";
import deployConfig from "../config/network.config";
import { ApiClient } from "../api";
// eslint-disable-next-line no-unused-vars
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { actions as SESSION_ACTIONS } from "../store/reducer/session";

export const APIContext = createContext({
  /**
   * @description End current session
   * @type {function}
   * @returns {void}
   */
  endSession: () => {
    return;
  },
});

export const APIProvider = ({ children }) => {
  const state = useSelector((state) => state);
  const token = state.session?.access,
    isLoggedIn = state.session?.isLoggedIn,
    dispatch = useDispatch();

  async function endSession() {
    dispatch(SESSION_ACTIONS.$CLEAR());
  }
  /**
   *
   * @param {AxiosError} error
   */
  function expiredSessionErrorHandler(error) {
    if (isLoggedIn) {
      let message = Array.isArray(error?.response?.data?.message)
        ? error?.response?.data?.message.join("\n")
        : error?.response?.data?.message || error.message;
      if (error.response && error.response?.status === 401) {
        console.log("Expired session handler", error?.response);
        endSession();
      }
    }
  }

  const API_CONFIG = {
    token,
    baseURL: deployConfig.baseURL,
    timeout: 5000,
    globalAxiosConfig: {
      params: {
        /* livemode: setting?.livemode */
      },
    },
    customErrorHandler: expiredSessionErrorHandler,
  };

  class SDK extends ApiClient {
    endSession() {
      return endSession();
    }
  }
  const API_SDK = new SDK(API_CONFIG);

  const API_CONTEXT_VALUE = API_SDK;

  return (
    <APIContext.Provider value={API_CONTEXT_VALUE}>
      {children}
    </APIContext.Provider>
  );
};
