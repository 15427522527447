import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { connectToStore } from "../../lib";

function TicketPage(props) {
  return (
    <StyledTicket>
      <Outlet />
    </StyledTicket>
  );
}

export default connectToStore(TicketPage);
const StyledTicket = styled.section`
  padding: 30px;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 15px;
  overflow-y: auto;
  @media screen and (max-width: 669px) {
    padding: 15px;
    row-gap: 15px;
  }
`;
