import { connectToStore, StyledContainer } from "../../lib";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import {Grid} from "@mantine/core"
import { List, Add } from "../../components/Activity";

function ActivityPage() {
  return (
    <StyledContainer>
      <Grid grow gutter={"lg"} >
        <Grid.Col md={4} className="grow-0">
          <Add />
        </Grid.Col>

        <Grid.Col span={8}>
          <List/>
        </Grid.Col>
      </Grid>
    </StyledContainer>
  );
}

export default connectToStore(ActivityPage);
