// ListTicket.jsx
import { useState } from "react";
import styled from "styled-components";
import { connectToStore } from "../../lib";
import ListCard from "../ListCard";

/**
 * @function ListTicket
 * @type {React.FC<{}>}
 */
function ListTicket() {
  return <ListCard></ListCard>;
}

export default connectToStore(ListTicket);

const StyledContainer = styled.section``;
