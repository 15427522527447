// light.theme.ts
import { MantineTheme } from "@mantine/core";
import defaultConfig from "./default";

const config: Partial<MantineTheme> = {
  ...defaultConfig,
  colorScheme: "light",
  colors: {
    ...defaultConfig?.colors,
    dark: [
      "#f6f6f6",
      "#acaebf",
      "#8c8fa3",
      "#666980",
      "#4d4f66",
      "#34354a",
      "#2b2c3d",
      "#1d1e30",
      "#0c0d21",
      "#01010a",
    ],
    background: [
      "#FCFCFD",
      "#F6f6f6",
      // "#ECEEF0",
      "#DDE1E5",
      "#CED4DA",
      "#C0C7CE",
      "#B3BBC3",
      "#A8B0B9",
      "#9DA6AE",
      "#929CA5",
      "#89929B",
    ],
  },
};

export default config;
