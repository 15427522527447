import { ApiBase } from "./base";
import Auth from "./auth";
import User from "./user";
import Wallet from "./wallet";
import Ticket from "./ticket";
import Judge from "./judge";
import Form from "./form";
import Elimination from "./elimination";
import Analytics from "./analytics";
import Contest from "./contest";
import Upload from "./upload";
import Event from "./event";
/**
 * @typedef {object} ProviderType
 * @prop {ApiBase} Auth: Auth;
 * */

export const defaultProviders = {
  Auth,
};

/**
 * @class
 * @name ApiClient
 */
export class ApiClient {
  /**
   * @type {import("./base").OptionType}
   */
  options;
  /**
   * @type {ProviderType}
   */
  _providers = {};
  _useProviders = {};
  /**
   * Creates an instance of Client
   * @param {import("./base").OptionType} options
   * @param {ProviderType} providers
   * @memberof Client
   * @returns
   */
  constructor(options, providers = defaultProviders) {
    // SET INITIAL OPTIONS
    // this._globalRequestConfig = globalRequestConfig;
    this.options = options;
    // SET PROVIDERS
    this.Auth = new Auth(options);
    this.User = new User(options);
    this.Event = new Event(options);
    this.Wallet = new Wallet(options);
    this.Elimination = new Elimination(options);
    this.Judge = new Judge(options);
    this.Ticket = new Ticket(options);
    this.Form = new Form(options);
    this.Contest = new Contest(options);
    this.Analytics = new Analytics(options);
    this.Upload = new Upload(options);
    // this.registerProviders(providers);
  }

  /**
   * @description format string as capital-case
   * @param {!string} name
   * @returns string
   */
  _capitalizeString = (name) =>
    name
      .split(/\S[-_]/gi)
      .map(
        (item) =>
          item?.charAt(0).toUpperCase() + item.substring(1).toLowerCase()
      )
      .join("");

  /**
   * @description Abort all services
   */
  abort() {
    // console.log("ABORTING ALL REQUEST...");
    Object.entries(this._providers).forEach(([key, value]) => {
      value?.abort();
    });
  }
  /**
   * @description Register service providers
   * @param {ProviderType} [providers]
   */
  registerProviders = (providers) => {
    try {
      Object.entries(providers).forEach(([pName, pValue]) => {
        // Ensure they are instances of Service
        if (!(pValue.prototype instanceof ApiBase)) {
          // delete this._providers[pName];
          throw new Error(`Service(${pName}) is not a valid service object`);
        }

        this[pName] = new pValue(this.options);
        this._providers[pName] = this[pName];
        // useService proxy name
        let useServiceName = `use${this._capitalizeString(pName)}Service`;

        // provision a new server using `useServicename`
        const fn = (options = this.options) => new pValue(options);
        this[useServiceName] = fn;

        // Add to useProviders collection
        this._useProviders[useServiceName] = fn;
      });
    } catch (error) {
      console.error(error);
    } finally {
      // console.log("Available providers", this._providers);
    }
  };

  /**
   *
   * @param {!string} name
   * @returns {ApiBase}
   */
  getProvider(name) {
    if (name in this._providers) {
      return this._providers[name];
    } else throw new Error(`API Service <${name}> does not exist!`);
  }

  getConfig() {
    return this.options;
  }

  endSession() {
    console.log("You will have to set this method");
  }
}

export default ApiClient;
