import styled from "styled-components";
import { connectToStore } from "../../lib";
import { Outlet } from "react-router-dom";

function AccountContestant() {
  return (
    <StyledContainer>
      <Outlet />
    </StyledContainer>
  );
}

export default connectToStore(AccountContestant);

const StyledContainer = styled.section`
  padding: 30px;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 15px;
  overflow-y: auto;
  @media screen and (max-width: 669px) {
    padding: 15px;
    row-gap: 15px;
  }
`;
