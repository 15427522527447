import styled from "styled-components";
import { MarkIcon } from "../Svgs";
import ManageImage from "../../images/manage.png";
import Triangles from "../../images/triangles.png";

const Row = ({ content }) => (
  <RowStyled>
    <div className="trans-background"></div>
    <MarkIcon fill="#37C351" />
    <span>{content}</span>
  </RowStyled>
);

const RowStyled = styled.div`
  margin-bottom: 15px;
  display: flex;
  width: fit-content;
  padding: 7px 12px 7px 150px;
  box-shadow: 4px 4px 0px rgba(9, 98, 98, 0.55);
  border-radius: 4px;
  position: relative;
  .trans-background {
    opacity: 0.2;
    background: linear-gradient(
      90deg,
      rgba(205, 244, 244, 0.69) 0%,
      rgba(169, 239, 239, 1) 98.44%
    );
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin-left: 17px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 30px;
  }
`;

const Manage = () => (
  <ManageStyled>
    <div className="content">
      <h2 className="title">Manage</h2>
      <p className="subtitle">
        Creating a contest with us means you can manage the following aspects of
        your event.{" "}
      </p>
      <Row content="User Registration & Onboarding" />
      <Row content="Event Forms" />
      <Row content="Tours & Tickets" />
      <Row content="Contestants" />
      <Row content="Judges" />
    </div>
    <div className="imageBx">
      <img src={ManageImage} className="manage-img" alt="" srcSet="" />
      <img src={Triangles} alt="" className="triangles" />
    </div>
  </ManageStyled>
);

export default Manage;

const ManageStyled = styled.div`
  padding-top: 20px;
  width: 100%;
  height: 500px;
  background: linear-gradient(
    267.54deg,
    rgba(8, 112, 247, 0.81) 46.46%,
    #243ac5 74.87%
  );
  background: black;
  display: flex;
  justify-content: space-between;
  .imageBx {
    margin-top: auto;
    position: relative;
    overflow-y: hidden;
    .manage-img {
      width: 700px;
      height: 450px;
      z-index: 4;
    }
    .triangles {
      position: absolute;
      bottom: 0;
      width: 150px;
      height: 150px;
      opacity: 0.2;
      left: 0;
      z-index: 1;
    }
  }
  .content {
    width: 593px;
    .title {
      font-weight: 700;
      margin-left: 150px;
      font-size: 32px;
      line-height: 65px;
      letter-spacing: -0.04em;
      color: #ffffff;
    }
    .subtitle {
      font-weight: 400;
      font-size: 16px;
      margin-left: 150px;
      margin-bottom: 32px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      .title,
      .subtitle {
        margin-left: 30px;
      }
    }
    .imageBx {
      display: none;
    }
  }
`;
