// ListEvent.jsx
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { connectToStore } from "../../lib";
import ListCard from "../ListCard";
import { useApi } from "../../hook";

function ListEvent() {
  const api = useApi();
  const isMounted = useRef(true);
  const [list, setList] = useState([]);
 useEffect(() => {
    if (!isMounted?.current) isMounted.current = true;

    fetchEvent();
    return () => {
      isMounted.current = false;
    };
  }, []);

 async function fetchEvent (){
  try{}catch(error){

  }
 }
  return <ListCard list={list}>{({ list }) => <></>}</ListCard>;
}

export default connectToStore(ListEvent);

const StyledContainer = styled.section``;
