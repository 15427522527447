// upload.js

import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "uploads");
    this._name = "uploads";
    return this;
  }

  /**
   * @param {object} args
   * @param {object} args.data
   * @param {FormData} args.data.file
   * @param {object} args.param
   * @param {boolean} args.param.livemode
   * @return
   */
  async create({ data, params }) {
    const stringParam = params ? new URLSearchParams(params) : "";

    const res = await this.axios({
      url: `/?${stringParam}`,
      method: "post",
      data,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    });
    return res.data;
  }
  /**
   * @param {object} args
   * @param {object} args.params
   * @param {string} args.params.mimetype__icontains
   */
  async list(args = { params: null }) {
    const stringParam = args?.params ? new URLSearchParams(args?.params) : "";

    const res = await this.axios({
      url: `/?${stringParam}`,
      method: "get",
    });
    return res.data;
  }

  /**
   * @function remove
   * @param {object} args
   * @param {string} id
   */
  async remove({ id }) {
    const res = await this.axios({
      url: `/${id}/`,
      method: "delete",
    });
    return res.data;
  }

  /**
   * @function bulkRemove
   * @param {object} args
   * @param {string[]} ids
   */
  async bulkRemove({ ids }) {
    const res = await this.axios({
      url: `/?${ids?.map((id) => `ids[]=${id}`)?.join("&")}`,
      method: "delete",
    });
    return res.data;
  }
}
