// ConnectedAccount.jsx
import * as React from "react";
import { connectToStore } from "../../lib";
import Card from "../Card";
import { Switch, Button } from "@mantine/core";
import { FaFacebookSquare, FaGoogle, FaTwitterSquare } from "react-icons/fa";

function ConnectedAccount() {
  function connectAccount(type) {
    switch (type) {
      case "google": {
        break;
      }
      case "twitter": {
        break;
      }
      case "facebook": {
        break;
      }
    }
    // After connecting, indicate if connected or not with a checkmark
  }

  return (
    <>
      <Card title={"Connected Accounts"}>
        <div className="flex items-center justify-between gap-x-[8px]">
          <span className="grow">Google</span>
          <Button
            variant="subtle"
            leftIcon={<FaGoogle />}
            onClick={() => connectAccount("google")}
          >
            Connect
          </Button>
        </div>

        <div className="flex items-center justify-between gap-x-[8px]">
          <span>Facebook</span>
          <Button
            leftIcon={<FaFacebookSquare />}
            variant="subtle"
            onClick={() => connectAccount("facebook")}
          >
            Connect
          </Button>
        </div>

        <div className="flex items-center justify-between gap-x-[8px]">
          <span>Twitter</span>
          <Button
            variant="subtle"
            leftIcon={<FaTwitterSquare />}
            onClick={() => connectAccount("twitter")}
          >
            Connect
          </Button>
        </div>
      </Card>
    </>
  );
}

export default connectToStore(ConnectedAccount);
