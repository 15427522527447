// wallet.js
import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "wallets");
    this._name = "Wallet";
    return this;
  }

  /**
   * @function create - Create a wallet
   * @params {object} args
   * @params {object} args.data
   * @params {string} args.data.currency
   *  */
  async create({ data }) {
    const res = await this.axios(
      {
        url: "/create/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function retrieve - Retrieve a single wallet data
   * @params {object} args
   * @params {string} args.id
   * */
  async retrieve({ id }) {
    const res = await this.axios(
      {
        url: `/${id}/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @function withdraw - Sends a wallet withdrawal request
   * @params {object} args
   * @params {string} args.id
   * @params {object} args.data
   * @params {number} args.data.amount
   * @params {object} args.data.bankaccount
   * @params {string} args.data.bankaccount.business_name
   * @params {string} args.data.bankaccount.business_mobile_number
   * @params {string} args.data.bankaccount.account_number
   * @params {string} args.data.bankaccount.email
   * @params {string} args.data.bankaccount.bank_name
   * @params {string} args.data.bankaccount.currency
   * @params {object} args.data.bankaccount.metadata
   * */
  async withdraw({ id, data }) {
    const res = await this.axios(
      {
        url: `/${id}/withdraw/`,
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function list - get a paginated list of wallets
   * @params {object} args
   * @params {Array<string>} args.ids
   * */
  async list({ ids = [] }) {
    const res = await this.axios(
      {
        url: `/?${ids?.map((id) => `ids[]=${id}`)?.join("&")}`,
        method: "get",
      },
      true
    );
    return res.data;
  }
}
