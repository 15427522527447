//Avatar.jsx
import styled from "styled-components";
import { connectToStore, firstLetters } from "../lib";
import { Avatar, Group, UnstyledButton } from '@mantine/core';
import {useNavigate} from "react-router-dom"
import {routeMap } from "../route-map"

function UserCard({ UserState, SessionState, linkable, children }) {
  const navigate = useNavigate();
  return (
          <StyledMenuBtn onClick={() => linkable ? navigate(routeMap?.account?.profile) : null}>
            <Group>
              {children}
               <Avatar color="cyan">
                    {firstLetters(UserState?.full_name || UserState?.username)}
                </Avatar>
            
            
            </Group>
          </StyledMenuBtn>
    
  );
}

export default connectToStore(UserCard);


const StyledMenuBtn = styled.div`
appearance: none;
border: none;
background: transparent;
display: flex;
align-items: center;
padding: 8px ;
border-radius: 8px;
color: ${({ theme }) =>
    theme.colorScheme === "dark"
      ? theme.colors.dark[1]
      : theme.colors.dark[5]};
&:hover {
  background: ${({ theme }) =>
    theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.colors.background[0]};
}
p {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  font-family: inherit
}

.user-details {
  max-width: 70px;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: currentColor
}
`