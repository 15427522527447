import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../hook/useAuth";
import { routeMap } from "../route-map";
import { bindActionCreators } from "redux";
import { actions as SESSION_ACTIONS } from "../store/reducer/session";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSession: bindActionCreators(SESSION_ACTIONS, dispatch),
});

const Connected = connect(mapStateToProps, mapDispatchToProps);

const AuthLayout = Connected(({ session }) => {
  return !session?.isLoggedIn ? (
    <>
      <main>
        {/* TODO: Set logo to middle */}
        <Outlet />
      </main>
    </>
  ) : (
    <Navigate to={routeMap?.account?.index} replace={true} />
  );
});

export default AuthLayout;
