// user.api.js

import { ApiBase } from "./base";

export default class Auth extends ApiBase {
  constructor(init) {
    super(init, "users");
    this._name = "users";
    return this;
  }
  /**
   * @function register - Register a new user
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.email
   * @param {string} args.data.username
   * @param {string} args.data.password
   * @param {string} args.data.re_password
   * @param {string} [args.data.referrer]
   * */
  async register({ data }) {
    const res = await this.axios(
      {
        url: "/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }
  /**
   * @function resetPassword - Reset password
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.email
   * */
  async resetPassword({ data }) {
    const res = await this.axios(
      {
        url: "/reset_password/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function confirmPasswordReset - confirm password reset with token and new password
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.uid
   * @param {string} args.data.token
   * @param {string} args.data.new_password
   * @param {string} args.data.re_new_password
   * */
  async confirmPasswordReset({ data }) {
    const res = await this.axios(
      {
        url: "/reset_password_confirm/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**	@function changePassword - Change user password for already authenticated user
   * 	@description change user password
   * 	@param {object} args
   * 	@param {object} args.data
   * 	@param {string} args.data.new_password
   * 	@param {string} args.data.re_new_password
   * 	@param {string} args.data.current_password
   * */
  async changePassword({ data }) {
    const res = await this.axios(
      {
        url: "/set_password/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  async deleteMe({ data }) {
    const res = await this.axios(
      {
        url: "/me/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @function updateMe - Update current session user profile
   * @param {object}  args
   * @param {object}  args.data
   * @param {string}  args.data.about
   * @param {string}  args.data.first_name
   * @param {string}  args.data.last_name
   * */
  async updateMe({ data }) {
    const res = await this.axios(
      {
        url: "/me/",
        method: "patch",
        data,
      },
      true
    );
    return res.data;
  }

  async me() {
    const res = await this.axios({
      url: "/me/",
      method: "get",
    });
    return res.data;
  }

  async list({ data }) {
    const res = await this.axios(
      {
        url: "/me/",
        method: "get",
      },
      true
    );
    return res.data;
  }

  /**
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.uid
   * @param {string} args.data.token
   * */
  async activation({ data }) {
    const res = await this.axios(
      {
        url: "/e/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.data.email
   * */
  async resendActivationMail({ data }) {
    const res = await this.axios(
      {
        url: "/resend_activation/",
        method: "post",
        data,
      },
      true
    );
    return res.data;
  }

  /**
   * @param {object} args
   * @param {object} args.data
   * @param {File} args.data.file
   * */
  async updateProfilePicture({ data }) {
    const res = await this.axios(
      {
        url: "/profile-picture/",
        method: "patch",
        data,
      },
      true
    );
    return res.data;
  }

  /** @function referralCode - Returns current session user's referral code
   * @param {object} args
   * @param {object} args.data
   * @param {File} args.data.file
   * */
  async retrieveById({ path }) {
    const res = await this.axios(
      {
        url: `/e/${path?.id}/`,
        method: "get",
      },
      true
    );
    return res.data;
  }

  /** @function referralCode - Returns current session user's referral code
   * @param {object} args
   * @param {object} args.data
   * @param {File} args.data.file
   * */
  async referralCode({ data }) {
    const res = await this.axios(
      {
        url: "/referral_code/",
        method: "get",
      },
      true
    );
    return res.data;
  }
}
