// colors.js
const COLORS = {
  backgroundColor: "#f2f2f2",
  primary: {
    main: "#f78f08",
    contrast: "#fff",
    light: "#faf7c3",
    dark: "#D76F00",
  },
  text: {
    main: "#202020",
    contrast: "#8C8C8C",
    dark: "#333",
    light: "#888",
  },
};

export default COLORS;
